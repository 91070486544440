import { actFormFields } from 'app/containers/ActForm/constants';
import { NO_ERRORS } from 'app/validations/act/actValidation';

const codeMustBeBilledAloneValidation = (act) => {
  const codesThatMustBeBilledAlone = ['15485', '15486', '15487'];

  const { codes } = act;

  const actCodesThatMustBeBilledAlone = act.codes.filter((code) => codesThatMustBeBilledAlone.includes(code.code));

  if (codes.length > 1 && actCodesThatMustBeBilledAlone.length > 0) {
    return {
      [actFormFields.codes]: {
        _error:
          actCodesThatMustBeBilledAlone.length > 1
            ? `Les codes ${actCodesThatMustBeBilledAlone.map((code) => code.code).join(', ')} doivent être facturés seuls`
            : `Le code ${actCodesThatMustBeBilledAlone[0].code} doit être facturé seul`
      }
    };
  }

  return NO_ERRORS;
};

export default codeMustBeBilledAloneValidation;
