import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { UserSpecialty } from 'shared/medyx-core/types/user';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import moment from 'moment-timezone';
import { ActivityType } from 'shared/medyx-core/types/activity';
import React, { useMemo } from 'react';
import Alert from 'app/components/Alert/Alert';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import { LumpSumDay } from '../../../../shared/domain/activity/lumpSum/model/LumpSum';

interface Props {
  lumpSumDays: LumpSumDay[];
}

const ActOverlapOnSameDayWarning = ({ lumpSumDays }: Props) => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (![UserSpecialty.ANESTHESIOLOGIST, UserSpecialty.ANESTHESIOLOGIST_INTENSIVIST].includes(userSpecialty)) {
    return false;
  }

  const hasOverlap = lumpSumDays.some((lumpSumDay) => {
    if (!lumpSumDay.start || !lumpSumDay.end) return false;

    const lumpSumDayRange = (moment as any).range(
      moment(lumpSumDay.start),
      moment(lumpSumDay.end).subtract(1, 'millisecond')
    );

    const activitiesInContext = selectActivitiesInContext()(getStore().getState()) ?? [];
    const overlappingActsInContext = activitiesInContext.filter(({ end, remp, start, status, type }) => {
      if (type !== ActivityType.ACT) return false;
      if (status === ActivityStatus.CANCELED) return false;

      const actStartDate = moment(remp ?? start);
      const actEndDate = end ? moment(end).subtract(1, 'millisecond') : actStartDate.clone();
      const actRange = (moment as any).range(actStartDate, actEndDate);

      return lumpSumDayRange.overlaps(actRange, { adjacent: true });
    });

    if (overlappingActsInContext.length === 0) return false;

    return true;
  });

  if (!hasOverlap) return null;

  return (
    <Alert
      severity={AlertSeverity.WARNING}
      noMargin
      showIcon
      title="Attention"
      message="
    Vous avez déjà un acte de facturé aux mêmes heures, le chevauchement n'est généralement pas permis.
    "
    />
  );
};

export default ActOverlapOnSameDayWarning;
