import { actFormFields } from 'app/containers/ActForm/constants';
import { NO_ERRORS } from 'app/validations/act/actValidation';
import moment from 'moment-timezone';
import PlaceDomain from 'shared/domain/place/model/PlaceDomain';

const codesOnlyAllowedOnWeekdays = ['15485', '15486', '15487'];

const START_OF_REGULAR_HOURS = 7;
const END_OF_REGULAR_HOURS = 17;

const weekdayRegularHoursValidation = (act) => {
  const { date, start, end, place } = act;

  const actCodesOnlyAllowedOnWeekdaysDuringRegularHours = act.codes.filter((code) =>
    codesOnlyAllowedOnWeekdays.includes(code.code)
  );

  if (actCodesOnlyAllowedOnWeekdaysDuringRegularHours.length === 0 || !act.start || !act.end) {
    return NO_ERRORS;
  }

  const startOfRegularHours = moment(date).clone().hour(START_OF_REGULAR_HOURS).minute(0).second(0);
  const endOfRegularHours = moment(date).clone().hour(END_OF_REGULAR_HOURS).minute(0).second(0);

  const isWeekendOrHoliday = PlaceDomain.fromDto(place).isWeekEndOrHoliday(date);
  const isOffRegularHours = moment(start).isBefore(startOfRegularHours) || moment(end).isAfter(endOfRegularHours);

  if (isWeekendOrHoliday || isOffRegularHours) {
    return {
      [actFormFields.codes]: {
        _error:
          actCodesOnlyAllowedOnWeekdaysDuringRegularHours.length > 1
            ? `Les codes ${actCodesOnlyAllowedOnWeekdaysDuringRegularHours.map((code) => code.code).join(', ')} peuvent uniquement être facturés entre ${startOfRegularHours.format('HH:mm')} et ${endOfRegularHours.format('HH:mm')} la semaine lors des jours non fériés`
            : `Le code ${actCodesOnlyAllowedOnWeekdaysDuringRegularHours[0].code} peut uniquement être facturé entre ${startOfRegularHours.format('HH:mm')} et ${endOfRegularHours.format('HH:mm')} la semaine lors des jours non fériés`
      }
    };
  }

  return NO_ERRORS;
};

export default weekdayRegularHoursValidation;
