import { getFeatureConfig } from '../../../userCustomization/featureToggle';
import {
  REQUIRED_DETAILS_FOR_INTERNISTS,
  REQUIRED_DETAILS_FOR_PNEUMOLOGISTS,
  REQUIRED_DETAILS_FOR_SURGERY
} from '../../../userCustomization/featureNames';
import amPm from './AmPm/amPm';
import apgar from './Apgar/apgar';
import dateHospitalisation from './DateHospitalisation/dateHospitalisation';
import dateCSST from './DateSST/dateCSST';
import exitDate from './ExitDate/exitDate';
import adjustEndTimeOptimization from './AdjustEndTimeOptimization/adjustEndTimeOptimization';
import endTimeIfMoreThan30Min from './EndTimeIfMoreThan30Min/endTimeIfMoreThan30Min';
import endTimeIfMoreThan60Min from './EndTimeIfMoreThan60Min/endTimeIfMoreThan60Min';
import endTimeRequired from './EndTimeRequired/endTimeRequired';
import followupType from './FollowupType/followupType';
import imc from './IMC/imc';
import injectionPhenolAlcool from './InjectionPhenolAlcool/injectionPhenolAlcool';
import intensiveCareDiagnostic from './IntensiveCareDiagnostic/intensiveCareDiagnostic';
import lieuResident from './LieuResident/lieuResident';
import multiDiagnostic from './MultipleDiagnostic/multipleDiagnostic';
import multiDebut from './MultiDebut/multiDebut';
import noAutorisation from './NoAutorisation/noAutorisation';
import notRequiredNoAutorisation from './NoAutorisation/notRequiredNoAutorisation';
import noAutorisationCfc from './NoAutorisationCfc/noAutorisationCfc';
import noAutorisationPcma from './NoAutorisationPCMA/noAuthorisationPcma';
import prefillStartTime from './PrefillStartTime/PrefillStartTime';
import quebecPharmacist from './QuebecPharmacist/quebecPharmacist';
import profAutre from './ProfAutre/profAutre';
import sexeBebe from './SexeBebe/sexeBebe';
import specialSituation from './SpecialSituation';
import breastReconstruction from './breastReconstruction/breastReconstruction';
import contralateralBreast from './ContralateralBreast/ContralateralBreast';
import adrenalContralateral from './AdrenalContralateral/AdrenalContralateral';
import referenceProfessional from './ReferenceProfessional/referenceProfessional';
import consultationCarriedOutElsewhereThanCRDS from './ConsultationCarriedOutElsewhereThanCRDS/ConsultationCarriedOutElsewhereThanCRDS';
import discontinuedService from './DiscontinuedService/discontinuedService';
import patientNoShow from './PatientNoShow/patientNoShow';
import OriginLocationOfRemoteConsultation from './OriginLocationOfRemoteConsultation/OriginLocationOfRemoteConsultation';
import DifferentChronicPainProblem from './DifferentChronicPainProblem/DifferentChronicPainProblem';
import IntensiveCareRole1StartTime from './IntensiveCareRole1StartTime/IntensiveCareRole1StartTime';
import quebecMidwife from './QuebecMidwife/quebecMidwife';

export const requiredDetailsAssociatedWithCode = (code, date) => {
  switch (code) {
    case '15387':
    case '15388':
    case '15390':
    case '78':
    case '99':
    case '9147':
    case '9296':
    case '9148':
      return [dateHospitalisation];

    case '927':
    case '991':
    case '41029':
    case '41030':
      return [multiDebut(3)];

    case '267':
      return [multiDebut(4), injectionPhenolAlcool];

    case '984':
      return [sexeBebe, apgar];

    case '911':
      return [adjustEndTimeOptimization];

    case '496':
    case '499':
    case '1010':
    case '1324':
    case '1338':
    case '1460':
    case '352':
    case '20202':
    case '20203':
    case '20204':
    case '2503':
    case '2504':
    case '4786':
    case '4787':
    case '4788':
    case '7095':
    case '7149':
      return [noAutorisation];

    case '1012':
    case '1021':
      return [noAutorisation];

    case '1234':
    case '1233':
      return [notRequiredNoAutorisation];

    case '1310':
    case '1311':
    case '1312':
    case '1405':
    case '1406':
    case '1416':
    case '1417':
      return [noAutorisationCfc];

    case '1029':
    case '1386':
    case '1388':
    case '1401':
    case '1402':
    case '1407':
    case '1409':
    case '1410':
    case '1419':
    case '1465':
      return [noAutorisationPcma];

    case '1463':
    case '1464':
      return [noAutorisationPcma];

    case '1365':
    case '1367':
    case '1373':
    case '1385':
    case '1423':
    case '1424':
    case '1425':
    case '1426':
    case '1176':
    case '1235':
    case '1041':
    case '1042':
    case '2322':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [breastReconstruction];
      }
      return [];

    case '1395':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [noAutorisationPcma, breastReconstruction];
      }
      return [noAutorisationPcma];

    case '847':
    case '848':
    case '849':
    case '20068':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [contralateralBreast];
      }
      return [];

    case '6058':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [{ ...imc, associatedFields: [{ ...imc.associatedFields[0], notRequired: true }] }];
      }

      return [];
    case '6087':
    case '6094':
    case '6945':
    case '6931':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [imc];
      }

      return [];

    case '1025':
    case '1026':
    case '1027':
    case '1028':
    case '3047':
    case '3048':
    case '3049':
    case '3050':
    case '5510':
    case '5511':
    case '5512':
    case '5513':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [imc];
      }

      return [];

    case '41033':
      return [imc];

    case '15120':
      return [sexeBebe];

    case '9097':
    case '9098':
      return [dateHospitalisation, intensiveCareDiagnostic];

    case '24':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_INTERNISTS, false)) {
        return [dateHospitalisation, intensiveCareDiagnostic, exitDate];
      }

      if (getFeatureConfig(REQUIRED_DETAILS_FOR_PNEUMOLOGISTS, false)) {
        return [dateHospitalisation, exitDate];
      }

      return [exitDate];

    case '35':
    case '38':
    case '41':
    case '53':
    case '54':
    case '693':
    case '803':
    case '897':
    case '898':
    case '899':
    case '928':
    case '9382':
    case '9383':
    case '15016':
    case '15398':
    case '15669':
    case '15670':
    case '15671':
    case '16050':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_INTERNISTS, false)) {
        return [dateHospitalisation, intensiveCareDiagnostic];
      }

      return [];

    case '45':
    case '15402':
    case '15403':
    case '15017':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_INTERNISTS, false)) {
        return [intensiveCareDiagnostic];
      }

      return [];

    case '15386':
    case '15389':
    case '9168':
    case '9176':
      return [dateHospitalisation, referenceProfessional];

    case '48':
      return [dateHospitalisation, referenceProfessional, multiDiagnostic];

    case '51':
      return [referenceProfessional, multiDiagnostic, endTimeIfMoreThan60Min];

    case '31':
    case '43':
      return [referenceProfessional, endTimeIfMoreThan60Min, multiDiagnostic];

    case '30':
      return [endTimeIfMoreThan30Min, multiDiagnostic];

    case '42':
      return [multiDiagnostic, endTimeIfMoreThan30Min];

    case '28':
    case '29':
    case '15672':
    case '15674':
      return [dateHospitalisation, multiDiagnostic];

    case '98101':
      return [OriginLocationOfRemoteConsultation, referenceProfessional];

    case '9127':
    case '9162':
    case '9201':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [followupType({ askFirstFollowup: false })];
      }

      return [];

    case '9212':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [referenceProfessional, followupType({ askFirstFollowup: false })];
      }

      return [referenceProfessional];

    case '9165':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [referenceProfessional, followupType({ askFirstFollowup: true })];
      }

      return [referenceProfessional];

    case '9170':
      // eslint-disable-next-line no-case-declarations
      const requiredDetails = [referenceProfessional];

      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        requiredDetails.push(followupType({ askFirstFollowup: true }));
      }

      return requiredDetails;

    case '9060':
    case '9161':
      return [dateHospitalisation];

    case '15235':
    case '15237':
    case '15238':
    case '9153':
    case '15360':
    case '15923':
    case '15924':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_PNEUMOLOGISTS, false)) {
        return [dateHospitalisation];
      }

      return [];

    case '9094':
    case '15236':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_PNEUMOLOGISTS, false)) {
        return [dateHospitalisation];
      }

      return [exitDate];

    case '98104':
      return [OriginLocationOfRemoteConsultation, quebecPharmacist];

    case '9403':
    case '9404':
    case '9405':
      return [endTimeRequired];

    case '9934':
      return [patientNoShow(date), dateCSST];

    case '9990':
      return [specialSituation];

    case '19700':
    case '19701':
    case '19702':
    case '19703':
    case '19750':
    case '19751':
    case '19752':
    case '19753':
    case '19754':
    case '19755':
    case '19756':
    case '19757':
    case '19758':
    case '19759':
    case '19760':
    case '19761':
    case '19762':
    case '19763':
    case '19764':
    case '19765':
    case '19766':
    case '19767':
    case '19768':
    case '19769':
      return [lieuResident];

    case '19704':
    case '19705':
    case '19706':
    case '19707':
    case '19708':
    case '19710':
    case '19711':
    case '19712':
    case '19713':
    case '19714':
    case '19715':
    case '19716':
    case '19717':
    case '19718':
    case '19719':
    case '19720':
    case '19721':
    case '19722':
    case '19723':
    case '19724':
    case '19725':
    case '19726':
    case '19727':
    case '19873':
    case '19748':
      return [lieuResident, amPm];

    case '41023':
    case '41024':
    case '41025':
    case '41039':
    case '41040':
    case '41041':
    case '41042':
    case '41043':
    case '41044':
    case '41049':
    case '41050':
    case '41051':
    case '41052':
    case '41053':
    case '41054':
    case '15396':
      return [dateHospitalisation];

    case '42080':
    case '42268':
      return [amPm];

    case '98102':
      return [OriginLocationOfRemoteConsultation, profAutre];

    case '217':
    case '20511':
    case '20512':
    case '255':
    case '258':
    case '792':
    case '259':
    case '260':
    case '261':
    case '262':
    case '719':
    case '263':
    case '264':
    case '265':
    case '720':
    case '20023':
    case '266':
    case '20513':
    case '20514':
    case '292':
    case '293':
    case '268':
    case '269':
    case '322':
    case '270':
    case '271':
    case '480':
      return [injectionPhenolAlcool];

    case '42158':
    case '42159':
    case '42160':
    case '42161':
    case '42162':
    case '42163':
    case '42164':
    case '42165':
    case '42166':
    case '42167':
    case '42168':
    case '42169':
    case '42170':
      return [prefillStartTime];

    case '6182':
      if (getFeatureConfig(REQUIRED_DETAILS_FOR_SURGERY, false)) {
        return [adrenalContralateral];
      }
      return [];

    case '15299':
      return [consultationCarriedOutElsewhereThanCRDS];

    case '42267':
    case '42269':
      return [endTimeRequired, discontinuedService];

    case '9108':
    case '9261':
    case '9282':
    case '9283':
    case '15183':
    case '15458':
    case '15459':
    case '15460':
    case '15461':
    case '15462':
    case '15708':
    case '15933':
    case '15934':
    case '17005':
    case '42051':
    case '98103':
      return [referenceProfessional];

    case '9257':
    case '9258':
      return [DifferentChronicPainProblem];

    case '8372':
      return [IntensiveCareRole1StartTime];

    case '15080':
    case '15081':
    case '15090':
      return [quebecMidwife];

    default:
      return [];
  }
};

/**
 * @deprecated Move code logic to individial classes
 */
export const getRequiredDetailsAssociatedWithCode = (currentCode, date) =>
  requiredDetailsAssociatedWithCode(currentCode.code, date);
