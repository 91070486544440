import { PatientDiagnostic } from '../../domain/activity/act/model/Act';
import { RAMQDiagnostics } from '../../ramq/RamqDiagnostics';

const CANCER_DIAGNOSTIC_KEYWORDS = [
  'tumeur',
  'maligne',
  'lymphome',
  'leucémie',
  'mélanome',
  'cancer',
  'carcinome',
  'sarcome'
];
const POTENTIAL_CANCER_DIAGNOSTIC_KEYWORDS = ['polype'];

const CANCER_DIAGNOSTIC_REGEX_STRING = CANCER_DIAGNOSTIC_KEYWORDS.map((keyword) => `(${keyword})`).join('|');
const POTENTIAL_CANCER_DIAGNOSTIC_REGEX_STRING = [
  ...CANCER_DIAGNOSTIC_KEYWORDS,
  ...POTENTIAL_CANCER_DIAGNOSTIC_KEYWORDS
]
  .map((keyword) => `(${keyword})`)
  .join('|');
const CASE_INSENSITIVE_REGEX = 'i';

const isCancerDiagnostic = (diagnostic: PatientDiagnostic) => {
  const ramqDiagnostic = RAMQDiagnostics.get(diagnostic.code);

  if (!ramqDiagnostic) {
    return false;
  }

  const regex = new RegExp(CANCER_DIAGNOSTIC_REGEX_STRING, CASE_INSENSITIVE_REGEX);
  return regex.test(ramqDiagnostic.description);
};

export const isPotentiallyCancerDiagnostic = (diagnostic: PatientDiagnostic) => {
  const ramqDiagnostic = RAMQDiagnostics.get(diagnostic.code);

  if (!ramqDiagnostic) {
    return false;
  }

  const regex = new RegExp(POTENTIAL_CANCER_DIAGNOSTIC_REGEX_STRING, CASE_INSENSITIVE_REGEX);
  return regex.test(ramqDiagnostic.description);
};

export default isCancerDiagnostic;
