import { dropWhile, findKey, keys, takeWhile } from 'lodash';
import moment from 'moment-timezone';
import { getHourFromTimestamp } from '../../ramq/ramqCommonUtils';
import { AM_PERDIEM, EVENING_PERDIEM, NIGHT_PERDIEM, PM_PERDIEM } from './constants';

export const timeSlotLimits = {
  nuit: { start: 0, end: 7 },
  am: { start: 7, end: 12 },
  pm: { start: 12, end: 19 },
  soir: { start: 19, end: 24 }
};

export const findTimeSlotFromTimestamp = (timestamp) => {
  const hour = getHourFromTimestamp(timestamp);
  const hourIsInTimeSlot = (timeSlot) => timeSlot.start <= hour && timeSlot.end > hour;
  return findKey(timeSlotLimits, hourIsInTimeSlot);
};

export const computeTimeSlotSpanFromTwoHoursInTheSameDay = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return [];
  }

  const startTimeSlot = findTimeSlotFromTimestamp(startTime);
  const endTimeSlot = findTimeSlotFromTimestamp(moment(endTime).add(-1, 'minutes'));
  const timeSlotSpan = getTimeSlotSpan(startTimeSlot, endTimeSlot);
  const completeTimeSlotSpan = addEndTimeSlotIfItIsNotAtTimeSlotLimit(timeSlotSpan, endTime);

  let referenceDayTimestamp = moment(startTime).startOf('day');

  return completeTimeSlotSpan.map((timeslot, index) => {
    if (index > 0 && timeslot === 'nuit') {
      referenceDayTimestamp = referenceDayTimestamp.add(1, 'day');
    }

    const start = getTimestampFromAccordingToTimeSlotBoudaries(startTime, timeslot, referenceDayTimestamp, 'start');
    const end = getTimestampFromAccordingToTimeSlotBoudaries(endTime, timeslot, referenceDayTimestamp, 'end');

    return { end, date: referenceDayTimestamp.valueOf(), start, timeslot };
  });
};

export const findTimeStampLimitsForTimeSlot = (timeSlot, referenceTimestamp) => {
  let timestampToReferTo = referenceTimestamp;

  if (!moment.isMoment(referenceTimestamp)) {
    timestampToReferTo = moment(referenceTimestamp);
  }

  const defaultParams = { minute: 0, second: 0, milliseconds: 0 };
  const startTimestamp = timestampToReferTo.set({ ...defaultParams, hour: timeSlotLimits[timeSlot].start }).valueOf();
  const endTimestamp = timestampToReferTo.set({ ...defaultParams, hour: timeSlotLimits[timeSlot].end }).valueOf();

  return { startTimestamp, endTimestamp };
};

const addEndTimeSlotIfItIsNotAtTimeSlotLimit = (timeSlotSpan, endTime) =>
  timestampIsAtTimeSlotLimit(moment(endTime).add(-1, 'minutes'))
    ? timeSlotSpan
    : timeSlotSpan.concat(findTimeSlotFromTimestamp(moment(endTime).add(-1, 'minutes')));

const timestampIsAtTimeSlotLimit = (timestamp) => {
  const timeSlotStartsExactlyAtTimeStamp = (timeslot) =>
    findTimeStampLimitsForTimeSlot(timeslot, timestamp).startTimestamp === timestamp;
  return !!keys(timeSlotLimits).find(timeSlotStartsExactlyAtTimeStamp);
};

const getTimeSlotSpan = (startTimeSlot, endTimeSlot) => {
  let keptTimeSlots = [NIGHT_PERDIEM, AM_PERDIEM, PM_PERDIEM, EVENING_PERDIEM, NIGHT_PERDIEM];

  keptTimeSlots = removeTimeSlotUntilStart(keptTimeSlots, startTimeSlot);

  return keepTimeSlotUntilEnd(keptTimeSlots, endTimeSlot);
};

const removeTimeSlotUntilStart = (array, startTimeSlot) => dropWhile(array, (timeslot) => timeslot !== startTimeSlot);

const keepTimeSlotUntilEnd = (array, endTimeSlot) => takeWhile(array, (timeslot) => timeslot !== endTimeSlot);

const getTimestampFromAccordingToTimeSlotBoudaries = (timestamp, timeslot, referenceDayTimestamp, boundaryFallback) => {
  const timeslotBoundaries = timeSlotLimits[timeslot];
  const timestampOptions = { hours: timeslotBoundaries[boundaryFallback], minutes: 0, seconds: 0, milliseconds: 0 };
  const startBoundary = moment(timestamp).set({
    hours: timeSlotLimits[timeslot].start,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
  const endBoundary = moment(timestamp).set({
    hours: timeSlotLimits[timeslot].end,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

  if (moment(timestamp).isBetween(startBoundary, endBoundary)) {
    const momentTimestamp = moment(timestamp);

    Object.assign(timestampOptions, {
      hours: momentTimestamp.format('k'),
      minutes: momentTimestamp.format('mm')
    });
    if (timestampOptions.hours === '24') {
      timestampOptions.hours = '0';
    }
  }

  return referenceDayTimestamp.clone().set(timestampOptions).valueOf();
};
