import { defineMessages } from 'react-intl';

export default defineMessages({
  start: {
    id: 'act.start',
    defaultMessage: 'Start'
  },
  end: {
    id: 'act.end',
    defaultMessage: 'End'
  },
  interval: {
    id: 'act.interval',
    defaultMessage: 'Intervalle'
  },
  remp: {
    id: 'act.remp',
    defaultMessage: 'Remp.'
  },
  codes: {
    id: 'act.codes',
    defaultMessage: 'Codes'
  },
  role3: {
    id: 'act.role3',
    defaultMessage: 'R3'
  },
  role4: {
    id: 'act.role4',
    defaultMessage: 'R4'
  },
  urgent: {
    id: 'act.urgent',
    defaultMessage: 'URG'
  },
  interrupt: {
    id: 'act.interrupt',
    defaultMessage: 'INTERR'
  },
  sdiff: {
    id: 'act.sdiff',
    defaultMessage: 'S.DIFF'
  },
  coeurPoumon: {
    id: 'act.coeurPoumon',
    defaultMessage: 'COEUR-POUMON'
  },
  greaterThan4Hours: {
    id: 'act.greaterThan4Hours',
    defaultMessage: '>4h / 8h'
  },
  anestheticComplexityGreaterThan4Hours: {
    id: 'act.anestheticComplexityGreaterThan4Hours',
    defaultMessage: '>4h Remp'
  },
  chronicPainInitialConsultationWithDifferentProblem: {
    id: 'act.chronicPainInitialConsultationWithDifferentProblem',
    defaultMessage: 'Douleur'
  },
  anestheticComplexityGreaterThan8Hours: {
    id: 'act.anestheticComplexityGreaterThan8Hours',
    defaultMessage: '>8h Remp'
  },
  intensiveCareUnitSupportDay: {
    id: 'act.intensiveCareUnitSupportDay',
    defaultMessage: 'S.I.'
  },
  intensiveCareOnGuardDay: {
    id: 'act.intensiveCareOnGuardDay',
    defaultMessage: 'Garde'
  },
  doctor: {
    id: 'act.doctor',
    defaultMessage: 'Doctor'
  },
  note: {
    id: 'act.note',
    defaultMessage: 'Note'
  },
  nonUrgent: {
    id: 'act.nonurgent',
    defaultMessage: 'Not Urgent'
  },
  sntNonAmbulatoireCovid: {
    id: 'act.sntNonAmbulatoireCovid',
    defaultMessage: 'SNT non ambulatoire COVID'
  },
  sntAmbulatoireCovid: {
    id: 'act.sntAmbulatoireCovid',
    defaultMessage: 'SNT ambulatoire COVID'
  },
  mdReaffecteCovidEtabliss: {
    id: 'act.mdReaffecteCovidEtabliss',
    defaultMessage: 'MD réaffecté  COVID (Établiss.)'
  },
  enceinteCovid: {
    id: 'act.enceinteCovid',
    defaultMessage: 'Enceinte COVID'
  },
  telemedecineCabinetCovid: {
    id: 'act.telemedecineCabinetCovid',
    defaultMessage: 'Télémédecine cabinet COVID'
  },
  telemedecineAilleursCovid: {
    id: 'act.telemedecineAilleursCovid',
    defaultMessage: 'Télémédecine ailleurs COVID'
  },
  telemedecineParTelephone: {
    id: 'act.telemedecineParTelephone',
    defaultMessage: 'Télémédecine par téléphone'
  },
  telemedecineParVisioconsultation: {
    id: 'act.telemedecineParVisioconsultation',
    defaultMessage: 'Télémédecine par visioconsultation'
  },
  mdInfecteCovid: {
    id: 'act.mdInfecteCovid',
    defaultMessage: 'MD infecté COVID'
  },
  isolementCovid: {
    id: 'act.isolementCovid',
    defaultMessage: 'Isolement COVID'
  },
  mdReaffecteAilleursCovid: {
    id: 'act.mdReaffecteAilleursCovid',
    defaultMessage: 'MD réaffecté ailleurs COVID'
  },
  servicePatientCovid: {
    id: 'act.servicePatientCovid',
    defaultMessage: 'Service patient COVID'
  },
  comments: {
    id: 'act.comments',
    defaultMessage: 'Comments'
  },
  sntNonAmbulatoire: {
    id: 'act.sntNonAmbulatoire',
    defaultMessage: 'SNT non ambulatoire'
  },
  sntAmbulatoireCliniqueDesigneEvaluation: {
    id: 'act.sntAmbulatoireCliniqueDesigneEvaluation',
    defaultMessage: 'SNT ambulatoire / CDD ou CDÉ'
  },
  mdReaffecte: {
    id: 'act.mdReaffecte',
    defaultMessage: 'MD réaffecté'
  },
  mdEnceinte: {
    id: 'act.mdEnceinte',
    defaultMessage: 'MD enceinte'
  },
  telemedecineCabinet: {
    id: 'act.telemedecineCabinet',
    defaultMessage: 'Télémédecine cabinet'
  },
  telemedecineHospitaliere: {
    id: 'act.telemedecineHospitaliere',
    defaultMessage: 'Télémédecine hospitalière'
  },
  mdInfecte: {
    id: 'act.mdInfecte',
    defaultMessage: 'MD infecté'
  },
  mdQuarantaine: {
    id: 'act.mdQuarantaine',
    defaultMessage: 'MD quarantaine'
  },
  mdReaffecteAutreLieu: {
    id: 'act.mdReaffecteAutreLieu',
    defaultMessage: 'MD réaffecté autre lieu'
  },
  webinaireDirect: {
    id: 'act.webinaireDirect',
    defaultMessage: 'Webinaire en direct'
  },
  webinaireDiffere: {
    id: 'act.webinaireDiffere',
    defaultMessage: 'Webinaire en différé'
  },
  serviceDiscontinuTelemedecine: {
    id: 'act.serviceDiscontinuTelemedecine',
    defaultMessage: 'Service discontinu en télémédecine'
  },
  activitesAnnulees: {
    id: 'act.activitesAnnulees',
    defaultMessage: 'Activités annulées'
  },
  aucunPerDiem: {
    id: 'act.aucunPerDiem',
    defaultMessage: 'Aucun per diem (Ann 38)'
  },
  demiPerDiem: {
    id: 'act.demiPerDiem',
    defaultMessage: 'Demi per diem (Ann 38)'
  },
  perDiem: {
    id: 'act.perDiem',
    defaultMessage: 'Per diem (Ann 38)'
  },
  createMore: {
    id: 'act.createMore',
    defaultMessage: 'Créer et ajouter plus'
  }
});
