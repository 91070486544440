import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TravelExpense from 'shared/core/activity/domain/TravelExpense';
import Place from '../../../shared/domain/place/model/Place';
import TRANSPORT_TYPES from '../../../shared/ramq/domainValues/transportTypes';
import TRAVEL_FEES_TYPES from '../../../shared/ramq/domainValues/travelFeesTypes';
import { PHYSICAL_LOCATION_TYPE } from '../../../shared/ramq/location/constants';
import {
  getActsInContextInTheSameDay,
  getLastPlaceUsedInContext
} from '../../codeSpecificLogic/ui/codeSpecificUILogicUtilities';
import BackButton from '../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { throwSubmissionErrorIfNecessary } from '../../validations/common/reduxFormValidation';
import validateTravelExpenses from '../../validations/travelExpenses/travelExpensesValidation';
import { selectActivitiesInContext, selectActivitiesLoading } from '../Activities/selectors';
import { selectLoggedUser } from '../Authentication/selectors';
import LoadPreferences from '../LoadPreferences/LoadPreferences';
import { selectPlacesPreferenceFromUser } from '../User/selectors';
import { saveTravelExpenses } from './actions';
import TravelExpensesForm from './TravelExpensesForm';
import AuthUserDomain from '../../../shared/domain/authentication/AuthUser';
import Permission from '../../../shared/domain/authorization/model/Permission';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';

export interface OwnProps {
  formClasses: any;
  travelExpenses?: TravelExpense;
}

export interface ReduxStateProps {
  user: AuthUserDomain;
  activitiesInContext: any[];
  activitiesFetching: boolean;
  placesPreference: Place[];
}

export interface Props extends OwnProps, ReduxStateProps {}

const TravelExpenses = ({
  travelExpenses: travelExpensesProp,
  activitiesFetching,
  formClasses,
  user,
  activitiesInContext,
  placesPreference
}: Props) => {
  const { date: dateParams, activityId } = useParams();
  const travelExpenses = useMemo(
    () => travelExpensesProp ?? activitiesInContext.find((activity) => activity.id === activityId),
    [travelExpensesProp, activitiesInContext, activityId]
  );
  const date = dateParams ? parseInt(dateParams, 10) : moment().valueOf();

  const navigate = useNavigate();
  const location = useLocation();

  const createDefaultTravelExpenses = () => {
    const activitiesOnSameDay = getActsInContextInTheSameDay(activitiesInContext, date);
    const defaultPlace = { type: PHYSICAL_LOCATION_TYPE };
    return {
      id: activitiesCollectionRef().doc().id,
      date,
      status: ActivityStatus.NO_STATUS,
      travelInformation: {
        startDate: date,
        startLocation: getLastPlaceUsedInContext(activitiesOnSameDay, placesPreference) || defaultPlace,
        endDate: date,
        endLocation: defaultPlace
      },
      fees: [
        {
          type: TRAVEL_FEES_TYPES.transportFee,
          transportType: TRANSPORT_TYPES.vehiculePerso,
          date
        }
      ]
    };
  };

  return (
    <LoadPreferences extraIsLoading={activitiesFetching}>
      <>
        <BackButton urlToGoOnClick="../.." position="left" preventRenderOnAdminConsole />
        <TravelExpensesForm
          classes={formClasses}
          travelExpenses={travelExpenses || createDefaultTravelExpenses()}
          onSave={
            user.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS)
              ? saveTransportFees(navigate, location)
              : () => null
          }
          onSent={createTransportFees(ActivityStatus.SENT, navigate, location)}
          onWait={createTransportFees(ActivityStatus.WAITING, navigate, location)}
        />
      </>
    </LoadPreferences>
  );
};

export const createTransportFees = (status, navigate, location) => (values, dispatch) => {
  const travelExpenses = {
    ...values,
    status
  };

  travelExpenses.date = travelExpenses.travelInformation.startDate; // Make it searchable in activities page
  throwSubmissionErrorIfNecessary(validateTravelExpenses(travelExpenses));
  dispatch(saveTravelExpenses(travelExpenses));
  navigate({ pathname: '../..', search: location.search });
};

export const saveTransportFees = (navigate, location) => (values, dispatch) => {
  const travelExpenses = values;
  travelExpenses.date = travelExpenses.travelInformation.startDate; // Make it searchable in activities page
  throwSubmissionErrorIfNecessary(validateTravelExpenses(travelExpenses));
  dispatch(saveTravelExpenses(travelExpenses));
  navigate({ pathname: '../..', search: location.search });
};

export const mapStateToProps = createStructuredSelector({
  placesPreference: selectPlacesPreferenceFromUser(),
  activitiesInContext: selectActivitiesInContext(),
  activitiesFetching: selectActivitiesLoading(),
  user: selectLoggedUser()
});

export default connect<ReduxStateProps, {}, OwnProps, {}>(mapStateToProps)(TravelExpenses);
