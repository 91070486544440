import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ACTIVITIES_ROUTE } from 'app/containers/Navigation/constants';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { getStartOfDay } from '../../../../shared/utils/dateTime/dateTimeUtils';
import BackButton from '../../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import validateAct from '../../../validations/act/actValidation';
import { throwSubmissionErrorIfNecessary } from '../../../validations/common/reduxFormValidation';
import { saveAct } from '../../Act/actions';
import { selectActivitiesInContext } from '../../Activities/selectors';
import LoadPreferences from '../../LoadPreferences/LoadPreferences';
import { selectPlacesPreferenceFromUser, selectUserProfileInContext } from '../../User/selectors';
import ActForm from '../ActForm';
import CreateActUseCase from '../../../act/shared/usecases/CreateActUseCase';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';

const NewAct = ({ placesPreference, activitiesInContext, userProfile }) => {
  const { date: dateParams } = useParams();
  const newActId = useMemo(() => activitiesCollectionRef().doc().id, []);
  const navigate = useNavigate();
  const location = useLocation();

  const date = getStartOfDay(parseInt(dateParams, 10)).valueOf();
  const act = new CreateActUseCase(
    placesPreference,
    activitiesInContext,
    userProfile?.billingType,
    userProfile?.defaultBillingType
  ).createWithDefaultProperties({ date, id: newActId });

  return (
    <LoadPreferences>
      <>
        <BackButton urlToGoOnClick="../.." position="left" />
        <ActForm
          onSent={saveActIfValid(ActivityStatus.SENT, userProfile, navigate, location, dateParams)}
          onWait={saveActIfValid(ActivityStatus.WAITING, userProfile, navigate, location, dateParams)}
          act={act}
        />
      </>
    </LoadPreferences>
  );
};

NewAct.propTypes = {
  placesPreference: PropTypes.array.isRequired,
  activitiesInContext: PropTypes.array.isRequired,
  userProfile: PropTypes.object
};

export const saveActIfValid = (status, user, navigate, location, date) => (values, dispatch) => {
  const actToSave = {
    ...values,
    status
  };
  throwSubmissionErrorIfNecessary(validateAct({ act: actToSave, user }));
  dispatch(saveAct(actToSave));
  if (date) {
    navigate({ pathname: `/${ACTIVITIES_ROUTE}/${actToSave.date.toString()}`, search: location.search });
  } else {
    // If date is not defined, it means we are in the console section, and a simple back redirect is enough
    navigate({ pathname: '..', search: location.search });
  }
};

export const mapStateToProps = createStructuredSelector({
  placesPreference: selectPlacesPreferenceFromUser(),
  activitiesInContext: selectActivitiesInContext(),
  userProfile: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps))(NewAct);
