import moment from 'moment';

const COMMUNICATION_THERAPY_CODE = '8925';
const VISIT_NATURE_TYPE = 'Visite';

export const communicationTherapyCodeBilledWithAnotherVisitCode = (act) => {
  const communicationTherapyCode = act.codes.find(({ code }) => code === COMMUNICATION_THERAPY_CODE);
  const visitCode = act.codes.find(({ natureType = '' }) => natureType === VISIT_NATURE_TYPE);

  if (!communicationTherapyCode || !visitCode) return {};

  const durationInMinutes = act.end && act.start ? moment(act.end).diff(moment(act.start), 'minutes') : 0;

  if (durationInMinutes >= 60) return {};

  return {
    codes: {
      _error: `Le code ${COMMUNICATION_THERAPY_CODE} ne peut être facturé avec un code de visite (${visitCode.code})
        si la durée est inférieur à 60 minutes`
    }
  };
};

export default communicationTherapyCodeBilledWithAnotherVisitCode;
