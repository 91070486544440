import { pick } from 'lodash';
import { put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import queryBuilder from '../../../shared/collection/QueryBuilder';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { getCollection, syncCollection } from '../../firebase/collection/collectionSync';
import { displayGlobalError, filterWatchedActs, updateWatchedActivities } from '../Act/actions';
import { selectWatchedActs } from '../Act/selectors';
import { selectUserIdInContext } from '../User/selectors';
import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  DUPLICATE_ACTIVITY,
  saveDuplicationInfo,
  getActivitiesError,
  getActivitiesSuccess,
  LOAD_MORE_ACTIVITIES,
  getMoreActivitiesSuccess,
  getMoreActivitiesError
} from './actions';
import {
  inferLumpSumDateRangeFromDate,
  inferMixteDateRangeFromDate
} from 'shared/medyx-core/utilities/period-date-range';

const ACT_PROCESSING_ERROR_MESSAGE =
  'La RAMQ éprouve présentement des difficultés techniques, les montants apparaîtront plus tard';

const filterDocumentsByErrorRamqExchange = (documents) =>
  documents.filter((doc) =>
    doc.response.some((exchange) => exchange.outcome === 'error' && exchange.createdOn > doc.watchingData.timestamp)
  );

export function* makeFirestoreSearch(userId, filters, options) {
  let periodDateRange = { dateRange: filters.dateRange };

  if (filters.type?.length === 1 && filters.type[0] === ActivityType.MIXTE && filters.dateRange) {
    periodDateRange.dateRange = {
      startDate: inferMixteDateRangeFromDate(filters.dateRange.startDate).start.valueOf(),
      endDate: inferMixteDateRangeFromDate(filters.dateRange.endDate ?? filters.dateRange.startDate).end.valueOf()
    };
  } else if (filters.type?.length === 1 && filters.type[0] === ActivityType.LUMP_SUM && filters.dateRange) {
    periodDateRange.dateRange = {
      startDate: inferLumpSumDateRangeFromDate(filters.dateRange.startDate).start.valueOf(),
      endDate: inferLumpSumDateRangeFromDate(filters.dateRange.endDate ?? filters.dateRange.startDate).end.valueOf()
    };
  }

  const query = queryBuilder
    .withBaseQuery(activitiesCollectionRef())
    .withUserId(userId)
    .sortOnDateField()
    .buildFromFilters({ ...filters, ...periodDateRange });

  if (options.makeGetRequest) {
    yield* getCollection(query, getActivitiesSuccess, getActivitiesError);
  } else {
    yield* syncCollection(query, getActivitiesSuccess, getActivitiesError);
  }
}

export function* makeFirestoreSearchMore(userId, filters) {
  const { startAfter, ...rest } = filters;
  let query = queryBuilder
    .withBaseQuery(activitiesCollectionRef())
    .withUserId(userId)
    .sortOnDateField()
    .buildFromFilters(rest);

  query = query.startAfter(parseInt(startAfter));

  yield* getCollection(query, getMoreActivitiesSuccess, getMoreActivitiesError);
}

export function* getUserActivities({ filters, options }) {
  const userId = yield select(selectUserIdInContext());

  yield* makeFirestoreSearch(userId, filters, options);
}

export function* loadMoreUserActivities({ filters }) {
  const userId = yield select(selectUserIdInContext());

  yield* makeFirestoreSearchMore(userId, filters);
}

const fuseWatchedActsAndDocuments = (actsDocuments, watchedActs) =>
  actsDocuments.reduce((watchedActDocuments, actDocument) => {
    const watchingData = { timestamp: watchedActs[actDocument.id], id: actDocument.id };
    if (!!actDocument.ramqExchange && !!watchingData.timestamp) {
      watchedActDocuments.push({
        watchingData,
        response: actDocument.ramqExchange
      });
    }
    return watchedActDocuments;
  }, []);

export function* validateRAMQServiceAvailability(action) {
  put(updateWatchedActivities());
  const actsDocuments = action.activities;
  const watchedActs = yield select(selectWatchedActs());
  const watchedActsDocument = fuseWatchedActsAndDocuments(actsDocuments, watchedActs);
  const erroredDocuments = filterDocumentsByErrorRamqExchange(watchedActsDocument);
  if (erroredDocuments.length > 0) {
    yield put(displayGlobalError(ACT_PROCESSING_ERROR_MESSAGE));
    yield put(filterWatchedActs(erroredDocuments));
  }
}

export const extractActivityDuplicationData = (activity) => {
  if (activity.type === ActivityType.TRAVEL_EXPENSES) {
    return {
      ...pick(activity, ['fees', 'travelInformation']),
      id: activitiesCollectionRef().doc().id
    };
  }
  return 'activity type not supported';
};

export function* duplicateActivity(action) {
  const { activity } = action;
  const duplicationData = extractActivityDuplicationData(activity);
  yield put(saveDuplicationInfo(activity.type, duplicationData));
}

export default function* activitiesSaga() {
  yield takeLatest(GET_ACTIVITIES, getUserActivities);
  yield takeLatest(LOAD_MORE_ACTIVITIES, loadMoreUserActivities);
  yield takeEvery(GET_ACTIVITIES_SUCCESS, validateRAMQServiceAvailability);
  yield takeLatest(DUPLICATE_ACTIVITY, duplicateActivity);
}
