import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVITIES_MIXTE_TYPE } from '../../../shared/collection/collectionConstants';
import { MIXTE_PERIOD_DAYS_DURATION } from '../../../shared/periods/constants';
import EmptyPlacePreferences from '../../components/Activities/EmptyPlacePreferences/EmptyPlacePreferences';
import BackButton from '../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import LoadPreferences from '../LoadPreferences/LoadPreferences';
import { extractPDF } from '../PDF/actions';
import PeriodForm from '../PeriodsBilling/PeriodForm/PeriodForm';
import PeriodsBilling from '../PeriodsBilling/PeriodsBilling';
import { saveMixte } from './actions';
import {
  createErrorMapWithEveryValidationFunction,
  throwSubmissionErrorIfNecessary
} from '../../validations/common/reduxFormValidation';
import validateMixte from '../../codeSpecificLogic/validations/mixte/mixteValidation';
import MixtePeriodTransformer from '../PeriodsBilling/PeriodForm/transformer/mixte/MixtePeriodTransformer';

const Mixte = ({ extractPDF, previousUrl = '..', saveMixteDocument, savedMixte, formClasses }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mixteTransformer = useMemo(() => new MixtePeriodTransformer(), []);

  return (
    <LoadPreferences>
      <>
        <BackButton urlToGoOnClick={previousUrl} position="left" preventRenderOnAdminConsole />
        <EmptyPlacePreferences text={<FormattedMessage id="emptyPlacePreferences.mixte" />}>
          <PeriodsBilling
            savedPeriod={savedMixte}
            periodDuration={MIXTE_PERIOD_DAYS_DURATION}
            activityType={ACTIVITIES_MIXTE_TYPE}
          >
            <PeriodForm
              classes={formClasses}
              onSave={(status) => (periodFormDto) => {
                throwSubmissionErrorIfNecessary(
                  createErrorMapWithEveryValidationFunction(periodFormDto, validateMixte(status))
                );

                let documentToSave = mixteTransformer.toDomainObject(periodFormDto);

                if (status) {
                  documentToSave = {
                    ...documentToSave,
                    status
                  };
                }

                saveMixteDocument(documentToSave);
                navigate({ pathname: previousUrl, search: location.search });
              }}
              onPDFButtonClick={(periodForm, isReadOnly) => {
                const documentToExtract = mixteTransformer.toDomainObject(periodForm);
                extractPDF(documentToExtract, isReadOnly);
              }}
            />
          </PeriodsBilling>
        </EmptyPlacePreferences>
      </>
    </LoadPreferences>
  );
};

Mixte.propTypes = {
  previousUrl: PropTypes.string,
  saveMixteDocument: PropTypes.func.isRequired,
  extractPDF: PropTypes.func.isRequired,
  savedMixte: PropTypes.object,
  formClasses: PropTypes.object
};

Mixte.defaultProps = {
  savedMixte: undefined,
  formClasses: {}
};

export const mapDispatchToProps = {
  saveMixteDocument: saveMixte,
  extractPDF
};

export default compose(connect(null, mapDispatchToProps))(Mixte);
