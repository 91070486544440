export const ID_FIELD = 'id';
export const TYPE_FIELD = 'type';
export const STATUS_FIELD = 'status';
export const ADMIN_STATUS_FIELD = 'adminStatus';
export const OTHER_FILTER = 'other';
export const USER_ID_FIELD = 'userId';
export const DATE_FIELD = 'date';
export const START_FIELD = 'start';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const PRACTICE_NUMBER_FIELD = 'practiceNumber';
export const FISCAL_YEAR_START_FIELD = 'fiscalYearStart';
export const BILL_NUMBER_MAP_FIELD = 'billNumberMap';
export const ATTESTATION_NUMBER_MAP_FIELD = 'attestationNumberMap';
export const PLACE_NUMBER_FIELD = 'placeNumber';
export const OUTSIDE_RAMQ_PATIENT_TYPE_FILTER = 'outsideRamqPatientType';
export const DAY_RANGE_FILTER = 'dayRange';
export const DATE_RANGE_FILTER = 'dateRange';
export const START_TIME_RANGE_FILTER = 'startTimeRange';
export const PERIOD_START_DATE_FILTER = 'startDate';
export const PERIOD_END_DATE_FILTER = 'endDate';
export const LIMIT_FILTER = 'limit';
export const NAM = 'nam';
export const BILLING_TYPE = 'billingType';
export const ELECTRONIC_SIGNATURE_SYNCHRONIZED = 'electronicSignatureSynchronized';
export const EXTERNAL_REFERENCE_NUMBER = 'externalReferenceNumber';
export const POOL_NUMBER = 'poolNumber';
export const TEXT_FILTER = 'textFilter';
