import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { createStructuredSelector } from 'reselect';
import { IconButton } from '@material-ui/core';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import BackButton from '../../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import { selectDuplicatedInfo } from '../../Act/selectors';
import LoadPreferences from '../../LoadPreferences/LoadPreferences';
import { ACTIVITIES_ROUTE } from '../../Navigation/constants';
import ActForm from '../ActForm';
import { saveActIfValid } from '../NewAct/NewAct';
import { selectUserProfileInContext } from '../../User/selectors';

export const styles = () => ({
  container: {
    flex: 1,
    maxWidth: 900,
    overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    top: '0'
  },
  activityDetailWrapper: {
    padding: '16px',
    paddingTop: 0,
    position: 'sticky',
    top: 0
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

// We are preventing the fact that the user refresh the page on duplicate route
// which would cause an empty act here (and a crash)
export function DuplicateAct({ duplicatedActInfo, userProfile, classes }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, date } = useParams();

  const shouldShowCloseButton = !!userId;

  return (
    <LoadPreferences>
      {duplicatedActInfo.date ? (
        <div className={classes.container}>
          <div className={classes.activityDetailWrapper}>
            {date && <BackButton urlToGoOnClick="../.." position="left" />}
            {shouldShowCloseButton && (
              <div className={classes.buttonWrapper}>
                <IconButton onClick={() => navigate({ pathname: '..', search: location.search })}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <ActForm
              onSent={saveActIfValid(ActivityStatus.SENT, userProfile, navigate, location, date)}
              onWait={saveActIfValid(ActivityStatus.WAITING, userProfile, navigate, location, date)}
              act={duplicatedActInfo}
            />
          </div>
        </div>
      ) : (
        <Navigate to={ACTIVITIES_ROUTE} />
      )}
    </LoadPreferences>
  );
}

DuplicateAct.propTypes = {
  duplicatedActInfo: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  duplicatedActInfo: selectDuplicatedInfo(),
  userProfile: selectUserProfileInContext()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(DuplicateAct);
