import { Act, ActContextElement } from 'shared/medyx-core/types/act';
import { NO_ERRORS } from 'app/validations/act/actValidation';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { UserSpecialty } from 'shared/medyx-core/types/user';

const VALIDATION_ACTIVATION_PER_SPECIALTY: Record<UserSpecialty, boolean> = {
  [UserSpecialty.ANESTHESIOLOGIST_INTENSIVIST]: true,
  [UserSpecialty.ANESTHESIOLOGIST]: true,
  [UserSpecialty.CARDIOLOGIST]: false,
  [UserSpecialty.ENDOCRINOLOGIST]: false,
  [UserSpecialty.GENERAL_SURGEON]: false,
  [UserSpecialty.INTERNIST]: false,
  [UserSpecialty.ORTHOPEDIC_SURGEON]: false,
  [UserSpecialty.OTOLARYNGOLOGIST]: false,
  [UserSpecialty.PNEUMOLOGIST]: false
};

const MUTUALLY_EXCLUSIVE_CONTEXT_ELEMENT_COMBINATIONS: Array<ActContextElement[]> = [
  [ActContextElement.ROLE3, ActContextElement.URGENT]
];

export default ({ contextElements = [] }: Act) => {
  if (contextElements.length <= 1) return NO_ERRORS;

  const userSpecialty = selectSpecialty()(getStore().getState());

  if (!VALIDATION_ACTIVATION_PER_SPECIALTY[userSpecialty]) return NO_ERRORS;

  for (const combination of MUTUALLY_EXCLUSIVE_CONTEXT_ELEMENT_COMBINATIONS) {
    const hasCombination = combination.every((item) => contextElements.includes(item));

    if (!hasCombination) continue;

    return {
      contextElements: `Les éléments de contexte ${combination.join(' et ')} ne peuvent pas être ajoutés sur un même acte.`
    };
  }

  return NO_ERRORS;
};
