import { Act } from './act';
import { AuditInformation } from './global';
import { LumpSum } from './lump-sum';
import { Mixte } from './mixte';
import { Training } from './training';
import { TravelExpenses } from './travel-expenses';

export type Activity = Act | Mixte | LumpSum | Training | TravelExpenses;

export enum ActivityType {
  ACT = 'act',
  MIXTE = 'mixte',
  LUMP_SUM = 'lumpSum',
  TRAVEL_EXPENSES = 'travelExpenses',
  TRAINING = 'training',
}

export interface ActivityPlace {
  rssName?: string;
  synchronized?: boolean;
  zipCode?: string;
  annexe19?: number;
  isCMS?: boolean;
  city?: string;
  municipality?: string;
  numbers?: Array<{
    effectiveEndDate: string | number | null;
    number: string;
    effectiveStartDate: string | number | null;
  }>;
  isCRDS?: boolean;
  daysOff?: string[];
  type: 'geographical' | 'physical';
  tarificationCode?: number;
  number: string;
  isCabinet?: boolean;
  id: string;
  physicalLocationType?: string;
  billByAct?: boolean;
  address?: string;
  rssCode?: number | string;
  priority?: number;
  name: string;
  signatory?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  category?: string;
  auditInformation?: AuditInformation;
  objectID?: string;
  distanceKM?: number;
  effectiveEndDate?: string | number | null;
  regionName?: string;
  newClosedLocationCode?: null;
  relatedMunicipalities?: string[];
  effectiveStartDate?: string | number | null;
  regionNumber?: string;
}

export enum ActivityBillingType {
  POOL = 'pool',
  PRIV = 'priv',
  END = 'end',
}

export enum ActivityStatus {
  NO_STATUS = 'na',
  SENT = 'sent',
  WAITING = 'waiting',
  NEED_FIX = 'needFix',
  PROCESSING = 'processing',
  PAID = 'paid',
  CANCELED = 'canceled',
}

export enum ActivityAdminStatus {
  NORMAL = 'normal',
  OUTSIDE_RAMQ = 'outsideRamq',
  MIXTE_DESIGNATION = 'designationMixte',
  TERRITORY_MAJORATION = 'territoryMajoration',
  URGENT_TIME_LIMIT = 'urgentTimeLimit',
  REVISION_DEROGATION = 'revisionDerogation',
  ERROR_AMOUNT = 'errorAmount',
  AWAITING_INCORPORATION = 'awaitingIncorporation',
  OTHER_FOLLOWUPS = 'otherFollowups',
  MANAGED_BY_DOCTOR = 'managedByDoctor',
}

export interface ActivityRamqExchange {
  attestationNumber: string;
  controlFileXml?: string;
  fileName?: string;
  receptionNumber?: string;
  ramqLogId?: string;
  fileTradeNumber?: string;
  paymentRequestXml?: string;
  ramqSuccessLogsId?: string[];
  type: ActivityRamqExchangeType;
  createdOn: number;
  outcome: 'error' | 'success' | 'failure';
  errorFile?: string;
  section?: Array<{
    attestationCode: string;
    registrationCode: string;
    errorTransmissionDate?: null | string;
    errorCreationDate?: number;
    paymentRequestDate?: number;
    transmittedPaymentCount?: string;
    lotErrorCount?: string;
    canceledPaymentCount?: string;
    validPaymentCount?: string;
    billedCount?: string;
    refusedPaymentCount?: string;
    practiceNumber?: string;
    paymentNCE?: string;
    paymentRankInShipment?: string;
    errorMessage?: string;
    errorCode?: string;
    paymentErrorSequence?: string;
    errorActionCode?: string;
  }>;
  errorCreationDate?: number;
  balance?: null | string;
  lines?: Array<{
    number: string;
    amount: string;
    type: 'RamqExplanationLineType' | 'RamqStatementLineType';
    explanation: string;
    outcome: 'error' | 'success' | 'failure';
  }>;
  statementProductionDate?: string;
  errorLog?: string;
  errorMessages?: string[];
}

export enum ActivityRamqExchangeType {
  PAYMENT_REQUEST_RESPONSE = 'RAMQPaymentRequestResponse',
  MODIFICATION_REQUEST_REPONSE = 'RAMQModificationRequestResponse',
  CANCEL_REQUEST_RESPONSE = 'RAMQCancelRequestResponse',
  PAYMENT_EXPLANATION_RESPONSE = 'RAMQPaymentExplanationResponse',
  DEFERRED_PAYMENT_REQUEST_RESPONSE = 'RAMQDeferredPaymentRequestResponse',
  DEFERRED_PAYMENT_CONFIRMATION = 'RAMQDeferredPaymentConfirmation',
  DEFERRED_PAYMENT_ERROR = 'RAMQDeferredPaymentError',
}

export enum ActivityAttachmentType {
  CARD_ATTACHMENT = 'cardAttachment',
  CARD_PICTURE = 'cardPicture',
}

export interface ActivityAttachment {
  temporary?: boolean;
  location: string;
  type: 'cardAttachment' | 'cardPicture';
  fileName?: string;
  originalFileName?: string;
}

export interface ActivityNote {
  date: number;
  author?: string;
  type?: 'default' | 'zeroDollarsWarning';
  content: string;
  isAcknowledgeable?: boolean;
}

export interface ActivityLastActiveBill {
  totalAmount: string;
  billNumber: string;
  communicationToken: string;
  lastBillLineNumber: number;
}
