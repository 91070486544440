import firebase from 'firebase/compat/app';
import ActivityOtherFilter from '../core/activity/domain/ActivityOtherFilter';
import { getUnixEndOfDay, getUnixStartOfDay } from '../utils/dateTime/dateTimeUtils';
import {
  ADMIN_STATUS_FIELD,
  BILLING_TYPE,
  DATE_FIELD,
  DATE_RANGE_FILTER,
  DAY_RANGE_FILTER,
  ELECTRONIC_SIGNATURE_SYNCHRONIZED,
  ID_FIELD,
  LIMIT_FILTER,
  NAM,
  OTHER_FILTER,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER,
  PERIOD_END_DATE_FILTER,
  PERIOD_START_DATE_FILTER,
  PLACE_NUMBER_FIELD,
  POOL_NUMBER,
  START_FIELD,
  START_TIME_RANGE_FILTER,
  STATUS_FIELD,
  TEXT_FILTER,
  TYPE_FIELD,
  USER_ID_FIELD
} from './queryConstants';
import { ActivityFilters } from './types';

export type UndefinedFilter = {
  undefined?: any;
};

export type QueryBuilderFilters = UndefinedFilter | ActivityFilters;

class QueryBuilder {
  private readonly filtersMapping: Record<keyof Omit<QueryBuilderFilters, 'undefined'>, Function>;
  private query: any;

  constructor() {
    this.filtersMapping = {
      [ID_FIELD]: this.withId,
      [STATUS_FIELD]: this.withStatus,
      [ADMIN_STATUS_FIELD]: this.withAdminStatus,
      [OUTSIDE_RAMQ_PATIENT_TYPE_FILTER]: this.withOutsideRamqPatientType,
      [OTHER_FILTER]: this.withOtherFilter,
      [TYPE_FIELD]: this.withType,
      [USER_ID_FIELD]: this.withUserId,
      [DATE_RANGE_FILTER]: this.withDateRange,
      [DATE_FIELD]: this.withDate,
      [START_TIME_RANGE_FILTER]: this.withStartTimeRange,
      [DAY_RANGE_FILTER]: this.withDayRange,
      [PLACE_NUMBER_FIELD]: this.withPlaceNumber,
      [PERIOD_START_DATE_FILTER]: this.withPeriodStartDate,
      [PERIOD_END_DATE_FILTER]: this.withPeriodEndDate,
      [LIMIT_FILTER]: this.withLimit,
      [BILLING_TYPE]: this.withBillingType,
      [POOL_NUMBER]: this.withPoolNumber,
      [ELECTRONIC_SIGNATURE_SYNCHRONIZED]: this.withElectronicSignatureSynchronized,
      [NAM]: this.withNam,
      [TEXT_FILTER]: this.withTextFilter
    };
  }

  withBaseQuery(initialFirebaseQuery) {
    this.query = initialFirebaseQuery;
    return this;
  }

  withId(id) {
    if (Array.isArray(id)) {
      this.query = this.query.where(ID_FIELD, 'in', id);
    } else {
      this.query = this.query.doc(id);
    }

    return this;
  }

  withStatus(status) {
    this.query = this.query.where(STATUS_FIELD, ...this.getWhereOperatorAndValue(status));
    return this;
  }

  withAdminStatus(adminStatus) {
    this.query = this.query.where(ADMIN_STATUS_FIELD, ...this.getWhereOperatorAndValue(adminStatus));
    return this;
  }

  withOutsideRamqPatientType(outsideRamqPatientType) {
    this.query = this.query.where(
      'patientInformation.outsideRamq.patientType',
      ...this.getWhereOperatorAndValue(outsideRamqPatientType)
    );
    return this;
  }

  withOtherFilter(otherFilters) {
    otherFilters.forEach((otherFilter) => {
      if (otherFilter === ActivityOtherFilter.UNSYNCHRONIZED) {
        this.query = this.query.where('synchronized', '==', false);
      }
    });

    return this;
  }

  withLimit(limitNumber) {
    this.query = this.query.limit(limitNumber);
    return this;
  }

  withBillingType(billingType) {
    this.query = this.query.where(BILLING_TYPE, ...this.getWhereOperatorAndValue(billingType));
    return this;
  }

  withPoolNumber(poolNumber) {
    this.query = this.query.where(POOL_NUMBER, '==', poolNumber);
    return this;
  }

  withType(type) {
    this.query = this.query.where(TYPE_FIELD, ...this.getWhereOperatorAndValue(type));
    return this;
  }

  withUserId(userId) {
    this.query = this.query.where(USER_ID_FIELD, '==', userId);
    return this;
  }

  withElectronicSignatureSynchronized(flag) {
    this.query = this.query.where(ELECTRONIC_SIGNATURE_SYNCHRONIZED, '==', flag);

    return this;
  }

  withDate(date) {
    this.query = this.query.where(DATE_FIELD, '==', date);

    return this;
  }

  withDateRange(dateRange) {
    if (dateRange.startDate || dateRange.endDate) {
      // if one of startDate or endDate is missing, we want to use the available one as both a starting and end point
      const unixStartDate = getUnixStartOfDay(dateRange.startDate || dateRange.endDate);
      const unixEndDate = getUnixEndOfDay(dateRange.endDate || dateRange.startDate);
      this.query = this.query.where(DATE_FIELD, '>=', unixStartDate).where(DATE_FIELD, '<=', unixEndDate);
    }
    return this;
  }

  withStartTimeRange(timeRange) {
    this.query = this.query.where(START_FIELD, '>=', timeRange.startTime).where(START_FIELD, '<=', timeRange.endTime);
    return this;
  }

  withDayRange(dayDate) {
    const startDate = getUnixStartOfDay(dayDate);
    const endDate = getUnixEndOfDay(dayDate);
    return this.withDateRange({ startDate, endDate });
  }

  withPlaceNumber(placeNumber) {
    this.query = this.query.where(PLACE_NUMBER_FIELD, '==', placeNumber);
    return this;
  }

  withPeriodStartDate(date) {
    this.query = this.query.where(PERIOD_START_DATE_FILTER, '==', date);
    return this;
  }

  withPeriodEndDate(date) {
    this.query = this.query.where(PERIOD_END_DATE_FILTER, '==', date);
    return this;
  }

  withNam(nam) {
    this.query = this.query.where(NAM, '==', nam);
    return this;
  }

  withTextFilter(textFilter) {
    const upperCaseTextFilter = textFilter.toUpperCase();
    if (upperCaseTextFilter.match(/^([A-Z]{4})(\d{2})(\d{2})(\d{2})(\d)(\d)$/)) {
      this.withNam(upperCaseTextFilter);
    } else {
      this.withPlaceNumber(textFilter);
    }
    return this;
  }

  sortOnDateField() {
    this.query = this.query.orderBy(DATE_FIELD);
    return this;
  }

  build() {
    return this.query;
  }

  buildFromFilters(
    filters: QueryBuilderFilters
  ): firebase.firestore.Query | firebase.firestore.CollectionReference | firebase.firestore.DocumentReference {
    if (!filters) {
      return this.query;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const $this = this;

    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined && $this.filtersMapping[key] !== undefined) {
        $this.filtersMapping[key].call($this, filters[key]);
      }
    });

    return this.query;
  }

  /**
   * Firebase currently have a limitation where only one "in" statement can be performed on a query at a time.
   * We have to avoid using it as much as possible by transforming inclusion of unique values into a basic equality operation.
   */
  private getWhereOperatorAndValue(input: string | string[]): ['in' | '==', string | string[]] {
    if (!Array.isArray(input)) {
      return ['==', input];
    }

    if (Array.isArray(input) && input.length === 1) {
      return ['==', input[0]];
    }

    return ['in', input];
  }
}

const queryBuilder = new QueryBuilder();
export default queryBuilder;
