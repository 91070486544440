import { DateRange } from '../types/date.type';

const MIXTE_REFERENCE_DATE = new Date('2018-01-07T05:00:00.000Z');
const MIXTE_PERIOD_DURATION = 14;

const LUMP_SUM_REFERENCE_DATE = new Date('2018-01-07T05:00:00.000Z');
const LUMP_SUM_PERIOD_DURATION = 7;

const differenceInDays = (date1: Date, date2: Date): number => {
  const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const addDays = (date: Date, days: number): Date => {
  return new Date(date.valueOf() + days * 24 * 60 * 60 * 1000);
};

const subtractDays = (date: Date, days: number): Date => {
  return new Date(date.valueOf() - days * 24 * 60 * 60 * 1000);
};

const subtractMilliseconds = (date: Date, milliseconds: number): Date => {
  return new Date(date.valueOf() - milliseconds);
};

/**
 * The periods are based on RAMQ so the date must be at 00:00 EST/EDT or else the period will be off
 * @param date The date to infer the period from. Must be at 00:00 EST/EDT
 * @param referenceDate The reference date for which we know the start date of the period
 * @param periodDuration The duration of the period in days
 * @returns
 */
const inferDateRangeFromDate = (date: Date, referenceDate: Date, periodDuration: number) => {
  const numberOfDaysBetweenDateAndDefaultBillingStartPeriodDate = differenceInDays(date, referenceDate);
  const daysFromNearestStartDate = numberOfDaysBetweenDateAndDefaultBillingStartPeriodDate % periodDuration;
  const start = subtractDays(date, daysFromNearestStartDate);
  const endDay = addDays(start, periodDuration);
  const end = subtractMilliseconds(endDay, 1);

  return { start, end };
};

/**
 * Infer the date range for a lump sum period from a given date
 * @param date The date to infer the period from. Must be at 00:00 EST/EDT
 * @returns The date range for the lump sum period
 */
export const inferLumpSumDateRangeFromDate = (date: Date): DateRange => {
  return inferDateRangeFromDate(date, LUMP_SUM_REFERENCE_DATE, LUMP_SUM_PERIOD_DURATION);
};

/**
 * Infer the date range for a mixte period from a given date
 * @param date The date to infer the period from. Must be at 00:00 EST/EDT
 * @returns The date range for the mixte period
 */
export const inferMixteDateRangeFromDate = (date: Date): DateRange => {
  return inferDateRangeFromDate(date, MIXTE_REFERENCE_DATE, MIXTE_PERIOD_DURATION);
};
