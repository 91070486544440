import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import UserAccountStatus from '../../../shared/domain/user/profile/UserAccountStatus';

const styles = () =>
  ({
    status: {
      width: 110,
      padding: '6px 8px',
      borderRadius: '4px',
      fontWeight: 500,
      textAlign: 'center'
    }
  }) as const;

export const USER_ACCOUNT_STATUS_COLORS = {
  [UserAccountStatus.ACTIVE]: '#1AFF1A',
  [UserAccountStatus.INACTIVE]: '#DC3220',
  [UserAccountStatus.DEMO]: '#FEFE62',
  [UserAccountStatus.INTERNAL]: '#785EF0',
  [UserAccountStatus.PAUSED]: '#E66100'
} as const;

export const USER_ACCOUNT_STATUS_TEXT = {
  [UserAccountStatus.ACTIVE]: 'Actif',
  [UserAccountStatus.INACTIVE]: 'Inactif',
  [UserAccountStatus.DEMO]: 'Demo',
  [UserAccountStatus.INTERNAL]: 'Interne',
  [UserAccountStatus.PAUSED]: 'Pause'
};

export interface Props extends WithStyles<typeof styles> {
  status: UserAccountStatus;
}

export const UserStatus: React.FunctionComponent<Props> = ({ classes, status }) => {
  const color = USER_ACCOUNT_STATUS_COLORS[status];

  return (
    <div className={classes.status} style={{ backgroundColor: color }}>
      {USER_ACCOUNT_STATUS_TEXT[status]}
    </div>
  );
};

const ReduxFormUserStatus: React.FunctionComponent<WrappedFieldProps & WithStyles<typeof styles>> = ({
  input,
  ...rest
}) => <UserStatus {...rest} status={input.value} />;

export default withStyles(styles)(ReduxFormUserStatus);
