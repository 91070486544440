import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { pick, keys } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { ACTIVITIES_ROUTE } from '../../Navigation/constants';
import { selectUserIdInContext } from '../../User/selectors';
import UsersSearchBox from '../UserRoute/UserActivities/UsersSearchBox/UsersSearchBox';
import { searchedUserIdsChanged } from '../actions';
import UserSearchResults from './UserSearchResults';
import SearchUrlParameterController from '../../ActivitiesSearch/SearchUrlParameter/SearchUrlParameterController';
import ActivityFilterApplier from '../../ActivitiesSearch/FilterApplier/ActivityFilterApplier';
import ActivityOtherFilter from '../../../../shared/core/activity/domain/ActivityOtherFilter';

export const styles = (theme) => ({
  searchContainer: {
    width: '98%',
    padding: theme.spacing(1),
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  singleSearch: {
    height: 'max-content',
    maxWidth: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
});

const PAGE_SIZE = 50;

const USERS_SEARCH_QUERY = gql`
  query SearchUsers($query: GetUsersInput!, $limit: Float!, $offset: Float!) {
    usersResult(query: $query, limit: $limit, offset: $offset) {
      count
      users {
        id
        status
        practiceNumber
        fullName
        specialty
        stripeProductId
        role

        stripeProductName

        facilities {
          id
          name
          number
          priority
        }

        activeAbsence {
          id
          reason
          startDate
          endDate
        }

        groups {
          id
          name
          type

          owners {
            id
            fullName
          }
        }
      }
    }

    userIds: usersResult(query: $query, limit: 10000) {
      users {
        id
      }
    }
  }
`;

const UsersSearch = ({ currentUserIdInContext, classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { queryParams, currentUserScope, pageNumber, formattedActivityActivityScope } = useMemo(() => {
    const queryParams = QueryString.parse(location.search, { ignoreQueryPrefix: true });

    const formattedActivityActivityScope = {
      ...(queryParams.textFilter && { searchTerm: queryParams.textFilter }),
      ...(queryParams.type && { types: queryParams.type }),
      ...(queryParams.status && { statuses: queryParams.status }),
      ...(queryParams.adminStatus && { adminStatuses: queryParams.adminStatus }),
      ...(queryParams.outsideRamqPatientType && {
        outsideRamqPatientTypes: queryParams.outsideRamqPatientType
      }),
      ...(queryParams.other &&
        queryParams.other.includes(ActivityOtherFilter.UNSYNCHRONIZED) && { synchronized: false }),
      ...(queryParams.dateRange?.startDate && { startDate: Number(queryParams.dateRange.startDate) }),
      ...(queryParams.dateRange?.endDate && { endDate: Number(queryParams.dateRange.endDate) })
    };

    return {
      formattedActivityActivityScope,
      queryParams,
      currentUserScope: queryParams.user ?? {},
      pageNumber: queryParams.pageNumber || 1
    };
  }, [location.search]);

  const { loading, data } = useQuery(USERS_SEARCH_QUERY, {
    variables: {
      query: {
        ...currentUserScope,
        ...(Object.keys(formattedActivityActivityScope).length && { activities: formattedActivityActivityScope })
      },
      limit: PAGE_SIZE,
      offset: (pageNumber - 1) * PAGE_SIZE
    }
  });

  useEffect(() => {
    const userIds = data?.userIds?.users?.map(({ id }) => id) ?? [];
    dispatch(searchedUserIdsChanged(userIds));
  }, [data?.userIds?.users, dispatch]);

  const updateSearchQueryParams = (params) => {
    const applicableUserScope = params.user || currentUserScope;

    const newSearchQuery = new SearchUrlParameterController(new ActivityFilterApplier()).newSearchQuery(
      {
        ...queryParams,
        ...(applicableUserScope && { user: applicableUserScope }),
        ...(params.pageNumber && { pageNumber: params.pageNumber })
      },
      null
    );
    navigate({ search: newSearchQuery });
  };

  return (
    <div className={classes.searchContainer}>
      <UsersSearchBox />
      <UserSearchResults
        title="Recherche d'utilisateurs"
        label="Prénom, nom, numéro de pratique..."
        loading={loading}
        users={data?.usersResult?.users ?? []}
        totalCount={data?.usersResult?.count ?? 0}
        pageSize={PAGE_SIZE}
        currentPage={pageNumber}
        searchTerm={queryParams.searchTerm}
        currentUserScope={currentUserScope}
        isSelectedFunction={(item) => item.id === currentUserIdInContext}
        onItemSelected={(userId) => {
          const currentParams = QueryString.parse(location.search, { ignoreQueryPrefix: true });
          const currentUserParams = pick(currentParams, [
            'other',
            'status',
            'adminStatus',
            'type',
            'dateRange',
            'outsideRamqPatientType',
            'textFilter'
          ]);

          const nextUserParams = keys(currentUserParams).length
            ? currentUserParams
            : {
                type: [ActivityType.ACT],
                status: [ActivityStatus.SENT]
              };

          navigate({
            pathname: `${userId}/${ACTIVITIES_ROUTE}`,
            search: QueryString.stringify(nextUserParams, { skipNulls: true, indices: true })
          });
        }}
        onPageChange={(pageNumber) => updateSearchQueryParams({ pageNumber })}
        onScopeChange={(scope) => updateSearchQueryParams({ user: scope })}
      />
    </div>
  );
};

UsersSearch.propTypes = {
  currentUserIdInContext: PropTypes.string.isRequired
};

export const mapStateToProps = createStructuredSelector({
  currentUserIdInContext: selectUserIdInContext()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(UsersSearch);
