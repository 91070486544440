import Activity from '../../global/model/Activity';
import ActivityType from '../../../../core/activity/domain/ActivityType';
import OutsideRamqBillingManager from '../patientInformation/outsideRamq/OutsideRamqBillingManager';

export enum OutsideRamqPatientType {
  REFUGEE = 'refugee',
  OUTSIDE_RAMQ = 'outsideRamq',
  PRIVATE_INSURANCE = 'privateInsurance',
  MILITARY = 'military',
  PRISONER = 'prisoner',
  OTHER = 'other',
  ABROAD_STUDENT = 'abroadStudent',
  BILLED = 'billed'
}

export const OutsideRamqPatientLabels = {
  refugee: 'Réfugié',
  outsideRamq: 'Hors RAMQ',
  privateInsurance: 'Assurance privée',
  military: 'Militaire',
  prisoner: 'Détenu',
  other: 'Autre',
  abroadStudent: 'Étudiant étrangé',
  billed: 'Facturé au patient'
};

type Act = Activity & {
  codes: ActCode[];
  start: number;
  end?: number;
  remp?: number;
  nam?: string;
  contextElements?: string[];
  ramqContextElements?: RAMQContextElement[];
  patientContext?: PatientContext;
  patientDiagnostic?: PatientDiagnostic[] | PatientDiagnostic;
  dateHospitalisation?: number;
  patientInformation?: {
    type?: string;
    shiftNamCentury?: boolean;
    province?: string;
    firstName?: string;
    lastName?: string;
    outsideRamq?: {
      allowMedyxToContactWithPatient?: boolean;
      billedDisclaimerAccepted?: boolean;
      billingManager?: OutsideRamqBillingManager;
      patientType?: OutsideRamqPatientType;
    };
  };
  activityArea?: string;
  xml?: string;
  lastActiveBill?: any;
  manualCorrection?: string;
  attachments?: Attachment[];
  guard?: boolean;
  urgentCallDetails?: {
    guard: boolean;
    date?: number;
    time?: number;
  };
  billNumberMap?: Record<string, boolean>;
};

export type Attachment = {
  fileName?: string;
  location?: string;
  temporary?: boolean;
  type?: string;
};

export type PatientContext = {
  admissionDate?: number;
  diagnostic?: any;
};

/** @deprecated * */
export type ActCode = {
  [key: string]: any;
  code: string;
  differentChronicPainProblem?: boolean;
  natureType?: NatureType;
  noStudents?: string[];
};

export type RAMQContextElement = {
  code: string;
  description: string;
};

export type PatientDiagnostic = {
  code: string;
  description?: string;
  medicalClassificationSystemId?: string;
};

/** @deprecated * */
export enum NatureType {
  ANESTHESIOLOGY = 'Anesthésiologie',
  PDT = 'PDT',
  SURGERY = 'Chirurgie',
  VISIT = 'Visite',
  FORFAIT = 'Forfait'
}

export function isAct(activity: Activity): activity is Act {
  return activity.type === ActivityType.ACT;
}

export default Act;
