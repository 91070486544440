import React, { PropsWithChildren } from 'react';
import queryString from 'qs';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useParams } from 'react-router-dom';
import { DefaultBillingType } from 'application/hooks/use-firestore-user-profile';
import { selectActivitiesInContext, selectActivitiesLoading } from '../Activities/selectors';
import LoadPreferences from '../LoadPreferences/LoadPreferences';
import { selectPlacesPreferenceFromUser, selectUserProfileInContext } from '../User/selectors';
import PeriodTransformerFactory from './PeriodForm/transformer/PeriodTransformerFactory';
import { Mixte } from '../../../shared/domain/activity/mixte/model/Mixte';
import LumpSum from '../../../shared/domain/activity/lumpSum/model/LumpSum';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import Activity from '../../../shared/domain/activity/global/model/Activity';
import Place from '../../../shared/domain/place/model/Place';
import PeriodsBillingGenerator, { Context } from './PeriodBillingGenerator';
import User from '../../../shared/domain/user/User';
import BillingType from '../../../shared/domain/billing/model/BillingType';

interface ReduxStateProps {
  placesPreferences: Place[];
  activities: Activity[];
  activitiesFetching: boolean;
  user: User;
}

interface OwnProps {
  activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM;
  savedPeriod?: Mixte | LumpSum;
}

export type Props = PropsWithChildren<OwnProps & ReduxStateProps>;

const PeriodsBilling = ({
  children,
  placesPreferences,
  savedPeriod,
  activities,
  user,
  activityType,
  activitiesFetching
}) => {
  const location = useLocation();
  const { date } = useParams();
  const { defaultBillingType } = useSelector(selectUserProfileInContext());

  const isOnline = navigator.onLine;

  const context: Context = {
    placesPreferences,
    user,
    activities
  };

  const parsePlaceQueryParams = (): string | undefined => {
    const { place } = queryString.parse(location.search, { ignoreQueryPrefix: true });
    return place?.toString();
  };

  const parseBillingTypeQueryParams = (): BillingType | undefined => {
    const { billingType } = queryString.parse(location.search, { ignoreQueryPrefix: true });

    switch (billingType) {
      case BillingType.END:
        return BillingType.END;
      case BillingType.POOL:
        return BillingType.POOL;
      case BillingType.PRIV:
        return BillingType.PRIV;
      default:
        return defaultBillingType !== DefaultBillingType.AUTO ? defaultBillingType : undefined;
    }
  };

  const parsePoolQueryParams = (): string | undefined => {
    const { pool } = queryString.parse(location.search, { ignoreQueryPrefix: true });
    return pool?.toString();
  };

  const period = new PeriodsBillingGenerator(context).getPeriod(
    activityType,
    savedPeriod,
    date!,
    parsePlaceQueryParams(),
    parseBillingTypeQueryParams(),
    parsePoolQueryParams()
  );

  const childrenWithGeneratedPeriod = React.cloneElement(children, {
    period: PeriodTransformerFactory.create(period.type).toPeriodFormDto(period)
  });
  return <LoadPreferences extraIsLoading={activitiesFetching}>{childrenWithGeneratedPeriod}</LoadPreferences>;
};

export const mapStateToProps = createStructuredSelector({
  placesPreferences: selectPlacesPreferenceFromUser(),
  activitiesFetching: selectActivitiesLoading(),
  activities: selectActivitiesInContext(),
  user: selectUserProfileInContext()
});

export default compose(connect<ReduxStateProps, {}, OwnProps, {}>(mapStateToProps))(PeriodsBilling);
