export const actCodes = [
  '803',
  '856',
  '897',
  '899',
  '9078',
  '9080',
  '9137',
  '9203',
  '9204',
  '9292',
  '9403',
  '9404',
  '9405',
  '15485',
  '15486',
  '15487',
  '41055',
  '41058',
  '42171',
  '42172',
  '42173',
  '42174',
  '42175',
  '42187',
  '42188',
  '42189',
  '42190',
  '42191',
  '42192',
  '42195',
  '42230',
  '42249',
  '42250',
  '5917'
];

export default actCodes;
