import Act, { ActCode, isAct } from 'shared/domain/activity/act/model/Act';
import Activity from 'shared/domain/activity/global/model/Activity';
import isDefined from 'shared/utils/isDefined';
import { isEmpty } from 'shared/utils/objectManipulation';

const NO_WARNINGS = [];
const IPAM_CODES = [
  '42330',
  '42331',
  '42332',
  '42333',
  '42334',
  '42335',
  '42336',
  '42337',
  '42338',
  '42339',
  '42340',
  '42341',
  '42342',
  '42343',
  '42344',
  '42345',
  '42346',
  '42347',
  '42348',
  '42349',
  '42350',
  '42351',
  '42352',
  '42353',
  '42354',
  '42355',
  '42356',
  '42357',
  '42358',
  '42359',
  '42360',
  '42361',
  '42362',
  '42363',
  '42364',
  '42365',
  '42366',
  '42367',
  '42368',
  '42369',
  '42370'
];

function IPAMmaxHoursWarning(form: Partial<Act>): string[] {
  const jsForm = form as Activity;

  if (isEmpty(jsForm) || !isAct(jsForm) || !isDefined(jsForm.place)) {
    return NO_WARNINGS;
  }

  if (!jsForm.codes.some(({ code }) => IPAM_CODES.includes(code))) {
    return NO_WARNINGS;
  }

  return [composeWarningMessage(jsForm.codes)];
}

const composeWarningMessage = (codes: ActCode[]) => {
  const isPlural = codes.length > 1;
  return `Le nombre d'heures maximal pour ${isPlural ? 'chacun des codes suivants: ' : 'le code'} ${codes.map(({ code }) => code).join(', ')} est de 7 heures par semaine et 70 heures par année civile.`;
};

export default IPAMmaxHoursWarning;
