import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useQuery } from '@tanstack/react-query';
import { StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Spinner from 'application/components/spinner';
import { selectUserProfileInContext } from '../../User/selectors';
import User from '../../../../shared/domain/user/User';
import OutsideRAMQForm from './OutsideRAMQForm';
import UserBillingType from '../../../../shared/domain/user/UserBillingType';
import ReportAPI from '../../../infrastructure/api/ReportAPI';

export const styles: StyleRulesCallback<Theme, Props> = (theme) =>
  ({
    spinner: {
      marginTop: theme.spacing(5)
    },
    iframeWrapper: {
      padding: theme.spacing(2),
      overflowX: 'scroll',
      height: '100%',
      width: '100%'
    },
    iframe: {
      height: '100%',
      width: '100%',
      minWidth: '1200px'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  doctor: User;
}

export const OutsideRamqPage: React.FunctionComponent<Props> = ({ doctor, classes }: Props) => {
  const [selectedYear, setSelectedYear] = useState<string>(moment().format('YYYY'));
  const [selectedBillingIdentifier, setSelectedBillingIdentifier] = useState<string>(UserBillingType.PRIVATE);

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['outside-ramq-report', selectedYear, selectedBillingIdentifier],
    queryFn: () =>
      ReportAPI.downloadDoctorOutsideRAMQReport({
        userId: doctor.id,
        year: selectedYear,
        mode: 'html',
        ...(selectedBillingIdentifier !== UserBillingType.PRIVATE && { groupId: selectedBillingIdentifier })
      }),
    enabled: !!doctor
  });

  if (!doctor) return;

  return (
    <>
      <OutsideRAMQForm
        selectedYear={selectedYear}
        onSelectedYearChange={setSelectedYear}
        selectedBillingIdentifier={selectedBillingIdentifier}
        onSelectedBillingIdentifierChange={setSelectedBillingIdentifier}
        doctor={doctor}
      />

      {isFetching && <Spinner className={classes.spinner} />}

      {data && isSuccess && !isFetching && (
        <div className={classes.iframeWrapper}>
          <iframe srcDoc={data} title="Report preview" className={classes.iframe} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  doctor: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps), withStyles(styles))(OutsideRamqPage) as React.FunctionComponent;
