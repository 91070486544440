import {
  CRDS_COORDINATOR_WITH_ACTE_REMUNERATION_PREFIX,
  CRDS_COORDINATOR_WITH_MIXTE_REMUNERATION_PREFIX,
  isCrdsCoordinationCode
} from './coordinatorCodePrefixors';
import { applyPrefix } from './codePrefixors.utils';
import {
  CRDS_RESPONDENT_WITH_ACTE_REMUNERATION_PREFIX,
  CRDS_RESPONDENT_WITH_MIXTE_REMUNERATION_PREFIX,
  isCrdsRespondentCode
} from './crdsRespondentCodePrefixors';
import {
  isOrganDonationCode,
  ORGAN_DONATION_WITH_ACTE_REMUNERATION_PREFIX,
  ORGAN_DONATION_WITH_MIXTE_REMUNERATION_PREFIX
} from './organDonationCodePrefixors';
import LUMPSUM_DISPENSARY_AREA_MAP from '../../../ramq/domainValues/lumpsumDispensaryAreas';
import { dateIsDuringPlaceHolidayOrWeekEnd } from '../../../utils/dateTime/dateValidations';
import {
  isSssNationalOrganismCode,
  SSS_NATIONAL_ORGANISM_WITH_ACTE_REMUNERATION_PREFIX,
  SSS_NATIONAL_ORGANISM_WITH_MIXTE_REMUNERATION_PREFIX
} from './sssNationalOrganismCodePrefixors';

const REGULAR_FULL_RATE_PREFIX = '250';
const TEACHING_FULL_RATE_PREFIX = '252';
const REGULAR_HALF_RATE_PREFIX = '251';
const TEACHING_HALF_RATE_PREFIX = '253';

export const LUMPSUM_TEACHING_CODES = ['xxx249', 'xxx250', 'xxx251', 'xxx252', 'xxx253', 'xxx254'];

export const addFullRatePrefix = ({ code }) => {
  let prefixor;

  if (isCrdsCoordinationCode(code)) {
    prefixor = applyPrefix(CRDS_COORDINATOR_WITH_ACTE_REMUNERATION_PREFIX);
  } else if (isCrdsRespondentCode(code)) {
    prefixor = applyPrefix(CRDS_RESPONDENT_WITH_ACTE_REMUNERATION_PREFIX);
  } else if (isOrganDonationCode(code)) {
    prefixor = applyPrefix(ORGAN_DONATION_WITH_ACTE_REMUNERATION_PREFIX);
  } else if (LUMPSUM_TEACHING_CODES.includes(code)) {
    prefixor = applyPrefix(TEACHING_FULL_RATE_PREFIX);
  } else if (isSssNationalOrganismCode(code)) {
    prefixor = applyPrefix(SSS_NATIONAL_ORGANISM_WITH_ACTE_REMUNERATION_PREFIX);
  } else {
    prefixor = applyPrefix(REGULAR_FULL_RATE_PREFIX);
  }

  return prefixor(code);
};

function isTeachingCode(code) {
  return LUMPSUM_TEACHING_CODES.includes(code);
}

export const addHalfRatePrefix = ({ code, activityArea, place, date }) => {
  let prefixor;

  if (isCrdsCoordinationCode(code)) {
    prefixor = applyPrefix(CRDS_COORDINATOR_WITH_MIXTE_REMUNERATION_PREFIX);
  } else if (isCrdsRespondentCode(code)) {
    prefixor = applyPrefix(CRDS_RESPONDENT_WITH_MIXTE_REMUNERATION_PREFIX);
  } else if (isOrganDonationCode(code)) {
    prefixor = applyPrefix(ORGAN_DONATION_WITH_MIXTE_REMUNERATION_PREFIX);
  } else if (isTeachingCode(code)) {
    prefixor = applyPrefixForTeachingCodes(activityArea);
  } else if (isSssNationalOrganismCode(code)) {
    prefixor = applyPrefix(SSS_NATIONAL_ORGANISM_WITH_MIXTE_REMUNERATION_PREFIX);
  } else {
    prefixor = applyPrefixForRegularCodes(code, activityArea, place, date);
  }

  return prefixor(code);
};

function applyPrefixForTeachingCodes(activityArea) {
  if ([LUMPSUM_DISPENSARY_AREA_MAP.remote, LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride].includes(activityArea)) {
    return applyPrefix(TEACHING_FULL_RATE_PREFIX);
  }

  return applyPrefix(TEACHING_HALF_RATE_PREFIX);
}

export const LE_241_PREFIXABLE_SUFFIX_CODES = ['370', '380'];
function applyPrefixForRegularCodes(code, activityArea, place, date) {
  // L/e 241 specific logic (MED-1648)
  if (LE_241_PREFIXABLE_SUFFIX_CODES.includes(code.substring(3)) && dateIsDuringPlaceHolidayOrWeekEnd(date, place)) {
    return applyPrefix(REGULAR_FULL_RATE_PREFIX);
  }

  if ([LUMPSUM_DISPENSARY_AREA_MAP.remote, LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride].includes(activityArea)) {
    return applyPrefix(REGULAR_FULL_RATE_PREFIX);
  }

  return applyPrefix(REGULAR_HALF_RATE_PREFIX);
}

export const codeHasFullRatePrefix = (code) =>
  code &&
  [
    CRDS_COORDINATOR_WITH_ACTE_REMUNERATION_PREFIX,
    CRDS_RESPONDENT_WITH_ACTE_REMUNERATION_PREFIX,
    ORGAN_DONATION_WITH_ACTE_REMUNERATION_PREFIX,
    REGULAR_FULL_RATE_PREFIX,
    TEACHING_FULL_RATE_PREFIX,
    '002',
    '049'
  ].includes(code.substring(0, 3));

export const codeHasHalfRatePrefix = (code) =>
  code &&
  [
    CRDS_COORDINATOR_WITH_MIXTE_REMUNERATION_PREFIX,
    CRDS_RESPONDENT_WITH_MIXTE_REMUNERATION_PREFIX,
    ORGAN_DONATION_WITH_MIXTE_REMUNERATION_PREFIX,
    REGULAR_HALF_RATE_PREFIX,
    TEACHING_HALF_RATE_PREFIX
  ].includes(code.substring(0, 3));
