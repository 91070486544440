import Act from 'shared/domain/activity/act/model/Act';
import { actFormFields } from 'app/containers/ActForm/constants';

const NO_ERRORS = {};

const IPAM_CODES = [
  '42330',
  '42331',
  '42332',
  '42333',
  '42334',
  '42335',
  '42336',
  '42337',
  '42338',
  '42339',
  '42340',
  '42341',
  '42342',
  '42343',
  '42344',
  '42345',
  '42346',
  '42347',
  '42348',
  '42349',
  '42350',
  '42351',
  '42352',
  '42353',
  '42354',
  '42355',
  '42356',
  '42357',
  '42358',
  '42359',
  '42360',
  '42361',
  '42362',
  '42363',
  '42364',
  '42365',
  '42366',
  '42367',
  '42368',
  '42369',
  '42370'
];

const endTimeForIPAMCodesValidation = (act: Act) => {
  if (act.end) return NO_ERRORS;

  if (!act.codes.some(({ code }) => IPAM_CODES.includes(code))) return NO_ERRORS;

  return {
    [actFormFields.end]: 'Ce champ est requis'
  };
};

export default endTimeForIPAMCodesValidation;
