import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Field } from 'redux-form';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { NumberField, PhoneNumberField, SelectorField, SingleLineField } from '../../../../components/Form/Fields';
import validateEmailFormat from '../../../../components/Form/utils/validator/emailValidator';
import { parsePhoneNumber } from '../../../../components/Form/utils/fieldTransformations';
import UserStatus from '../../../../components/UserStatus/UserStatus';
import SpecialtyName from '../../../../../shared/core/doctor/domain/SpecialtyName';
import { User } from 'shared/medyx-core/types/user';

export const specialtyList = [
  {
    value: SpecialtyName.ANESTHESIOLOGIST,
    label: 'Anesthésiologie'
  },
  {
    value: SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST,
    label: 'Anesthésiologie intensiviste'
  },
  {
    value: SpecialtyName.CARDIOLOGIST,
    label: 'Cardiologie'
  },
  {
    value: SpecialtyName.GENERAL_SURGEON,
    label: 'Chirurgie générale'
  },
  {
    value: SpecialtyName.ORTHOPEDIC_SURGEON,
    label: 'Chirurgie orthopédique'
  },
  {
    value: SpecialtyName.ENDOCRINOLOGIST,
    label: 'Endocrinologie'
  },
  {
    value: SpecialtyName.INTERNIST,
    label: 'Médecine interne'
  },
  {
    value: SpecialtyName.OTOLARYNGOLOGIST,
    label: 'Oto-rhino-laryngologie'
  },
  {
    value: SpecialtyName.PNEUMOLOGIST,
    label: 'Pneumologie'
  }
];
export const titleList = ['Dr', 'Dre'];

interface Props {
  language: 'en' | 'fr';
}

export const GeneralInformations: React.FunctionComponent<Props> = ({ language }) => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography gutterBottom variant="h6">
          Informations personnelles
        </Typography>
      </Grid>

      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
        {/* @ts-ignore */}
        <Field name="userStatus" component={UserStatus} />
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={2}>
        <FormElement name="title" label="Titre" component={SelectorField} list={titleList} />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormElement name="firstName" label="Prénom" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormElement name="lastName" label="Nom" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="specialty" label="Spécialité" component={SelectorField} list={specialtyList} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="practiceNumber" label="Numéro de Pratique" component={NumberField} />
      </Grid>

      <Grid item xs={12} sm={12} style={{ marginTop: '24px' }}>
        <Typography gutterBottom variant="h6">
          Contact
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="email"
          type="email"
          label="Courriel"
          component={SingleLineField}
          validate={validateEmailFormat}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="phone" label="Cellulaire" component={PhoneNumberField} parse={parsePhoneNumber} />
      </Grid>

      <Grid item xs={12} sm={12} style={{ marginTop: '24px' }}>
        <Typography gutterBottom variant="h6">
          Adresse
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="address" label="Adresse" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="city" label="Ville" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="province" label="Province" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="zipCode" label="Code Postal" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant="h6">
          Langue utilisée dans l'application
        </Typography>
        <div>{language === 'en' ? 'Anglais' : 'Français'}</div>
      </Grid>
    </Grid>
  </>
);
