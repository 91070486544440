import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import { OutsideRamqPatientType } from '../../../shared/domain/activity/act/model/Act';
import AdminStatus from '../../../shared/core/activity/domain/AdminStatus';
import PATIENT_WITHOUT_ID_TYPE from '../../../shared/ramq/domainValues/situationTypes';
import WAITING_PERIOD_SITUATIONS from '../../../shared/ramq/domainValues/waitingPeriod';

export const ACT_FORM_NAME = 'actForm';

export const POSSIBLE_STATUS = [
  ActivityStatus.WAITING,
  ActivityStatus.SENT,
  ActivityStatus.PROCESSING,
  ActivityStatus.NEED_FIX,
  ActivityStatus.PAID,
  ActivityStatus.CANCELED
];

export const POSSIBLE_ADMIN_STATUS = Object.values(AdminStatus);

export const POSSIBLE_OUTSIDE_RAMQ_PATIENT_TYPES = Object.values(OutsideRamqPatientType);

export const isValidStatus = (value) => POSSIBLE_STATUS.includes(value);

export const PATIENT_TYPE_SELECTION = [
  { label: '', value: '' },
  { label: "Enfant âgé de moins d'un an", value: PATIENT_WITHOUT_ID_TYPE.childUnderOneYear },
  { label: 'Patient requérant des soins urgents', value: PATIENT_WITHOUT_ID_TYPE.patientNeedingUrgentCare },
  {
    label:
      "Patient admis dans un CHSLD (incluant ceux pour les autochtones/cris), centre d'accueil ou centre de réadaptation",
    value: PATIENT_WITHOUT_ID_TYPE.patientInCHSLD
  },
  {
    label: "Patient âgé entre 14 ans et 18 ans sans l'autorisation des parents",
    value: PATIENT_WITHOUT_ID_TYPE.patientBetween14And18WithoutAuthorization
  },
  {
    label: "Personne faisant l'objet d'une ordonnance du tribunal pour l'évaluation de son état mental",
    value: PATIENT_WITHOUT_ID_TYPE.personWithTribunalRequestToAttestMentalState
  },
  {
    label: 'Service professionnel à la demande du coroner',
    value: PATIENT_WITHOUT_ID_TYPE.personFromCoronerRequest
  },
  {
    label: "Demandeur d'asile lorsque refus de paiement des services médicaux par Croix Bleue Medavie",
    value: PATIENT_WITHOUT_ID_TYPE.asylumAsker
  },
  {
    label: "Dans le cadre d'une demande de remboursement à remettre au patient",
    value: PATIENT_WITHOUT_ID_TYPE.refundRequest
  },
  {
    label: 'Donneur non québecois',
    value: PATIENT_WITHOUT_ID_TYPE.nonQuebecDonor
  },
  {
    label:
      'Personne requérant un service rendu dans le cadre des lois relatives à la santé et à la sécurité du travail',
    value: PATIENT_WITHOUT_ID_TYPE.patientRequiringServiceRenderedUnderLawsRelatingOccupationalSealthAndSafety
  }
];

export const carenceList = [
  {
    label: '',
    value: ''
  },
  {
    value: WAITING_PERIOD_SITUATIONS.requiredForDomesticViolenceOrAggression,
    label: "Délai de carence, services nécessaires aux victimes de violence conjugale ou familiale ou d'une agression"
  },
  {
    value: WAITING_PERIOD_SITUATIONS.pregnancyBirthAbortion,
    label: "Délai de carence, services liés à la grossesse, à l'accouchement ou à l'interruption de grossesse"
  },
  {
    value: WAITING_PERIOD_SITUATIONS.publicHealthThreat,
    label:
      'Délai de carence, services nécessaires aux personnes aux prises avec problèmes de santé de nature infectieuse ' +
      'ayant une incidence sur la santé publique'
  },
  {
    value: WAITING_PERIOD_SITUATIONS.insufficientLastResortHelp,
    label:
      'Patient requérant des soins urgents, détenteur de carnet de réclamation dont la durée de présence ' +
      'à l’aide financière de dernier recours est insuffisante'
  }
];

export const actFormFields = {
  id: 'id',
  type: 'type',
  assets: 'assets',
  attachments: 'attachments',
  uploadAttachment: 'uploadAttachment',
  date: 'date',
  status: 'status',
  place: 'place',
  activityArea: 'activityArea',
  nam: 'nam',
  patientEvent: 'patientEvent',
  patientContext: 'patientContext',
  patientEventDate: 'patientEvent.date',
  patientEventType: 'patientEventType',
  patientInformation: 'patientInformation',
  patientDiagnostic: 'patientDiagnostic',
  freeTextPatientDiagnostic: 'freeTextPatientDiagnostic',
  dateHospitalisation: 'dateHospitalisation',
  start: 'start',
  end: 'end',
  remp: 'remp',
  codes: 'codes',
  contextElements: 'contextElements',
  ramqContextElements: 'ramqContextElements',
  doctor: 'doctor',
  note: 'note',
  corrections: 'corrections',
  billingType: 'billingType',
  warningsDisplay: 'warningsDisplay',
  guard: 'guard',
  poolNumber: 'poolNumber',
  extractedNams: 'extractedNams',
  urgentCallDetails: 'urgentCallDetails',
  ramqPatient: 'ramqPatient'
};

export const actFormAdminFields = {
  ...actFormFields,
  billNumberMap: 'billNumberMap',
  billNumbersToRecover: 'billNumbersToRecover',
  manualCorrection: 'manualCorrection',
  paidAmount: 'paidAmount'
};
