import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'react-intl';
import Icon from 'application/components/icon';
import classNames from 'classnames';

interface Props {
  slug: string;
  className?: string;
}

const Markdown = ({ slug, className }: Props) => {
  const intl = useIntl();
  const [markdown, setMarkdown] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMarkdown = async (): Promise<string | undefined> => {
      const file = (await import(`../../markdown/${intl.locale}/${slug}.md`)) as { default: string } | undefined;
      if (!file) return;

      const response = await fetch(file.default);
      return response.text();
    };

    setLoading(true);
    fetchMarkdown().then((markdown) => {
      setMarkdown(markdown);
      setLoading(false);
    });
  }, [slug, intl]);

  if (loading) {
    return (
      <div className="w-full py-12 text-center text-4xl">
        <Icon loading />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        className,
        'w-full',
        'prose max-w-none',
        'prose-p:text-left prose-p:text-sm prose-p:text-neutral-900',
        'prose-li:text-left prose-li:text-sm prose-li:text-neutral-900',
        'prose-a:text-primary-900 prose-a:no-underline'
      )}
    >
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </div>
  );
};

export default Markdown;
