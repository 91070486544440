import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import RestrictedOutlet from 'application/components/restricted-outlet';
import OfflineNetworkWarning from 'application/components/offline-network-warning';
import GlobalMessageSnackBar from '../../components/Errors/globalErrorSnackBar/GlobalMessageSnackBar';
import FullScreenLoading from '../../components/Loadings/FullScreenLoading/FullScreenLoading';
import Login from '../../components/Login/Login';
import { selectLoggedUser } from '../Authentication/selectors';
import Main from '../Main/Main';
import { LOGIN_ROUTE } from '../Navigation/constants';
import { selectUserProfileInContext } from '../User/selectors';
import CrashHandler from './CrashHandler/CrashHandler';
import AuthUserDomain from '../../../shared/domain/authentication/AuthUser';
import { setUserContext as setSentryUserContext } from '../../../utils/sentry';
import VersionCheckerSnackbar from '../../components/VersionChecker/VersionCheckerSnackbar';
import LobbyOutlet from '../../../application/components/lobby-outlet';
import { usePostHog } from 'posthog-js/react';

export interface Props {
  authenticatedUser: AuthUserDomain;
  profile: any;
  redirectHome: any;
}

export interface State {
  appIsCrashed: boolean;
}

const App = ({ profile, authenticatedUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    if (!profile) return;

    const authUserDomain = authenticatedUser as AuthUserDomain;
    setSentryUserContext(profile, authUserDomain);
    const { ramqUsername } = profile;
    posthog.identify(authUserDomain.getId(), { email: authUserDomain.getEmail(), hasRamqCredentials: !!ramqUsername });
  }, [profile, authenticatedUser, posthog]);

  const isLoading = () => authenticatedUser.isPending();

  return (
    <CrashHandler onClose={() => navigate({ pathname: '/', search: location.search })}>
      {!isLoading() ? (
        <>
          <GlobalMessageSnackBar />
          <VersionCheckerSnackbar />
          <OfflineNetworkWarning />
          <Routes>
            <Route element={<LobbyOutlet />}>
              <Route path={`/${LOGIN_ROUTE}/*`} element={<Login />} />
            </Route>
            <Route element={<RestrictedOutlet />}>
              <Route path="/*" element={<Main />} />
            </Route>
          </Routes>
        </>
      ) : (
        <FullScreenLoading dark />
      )}
    </CrashHandler>
  );
};

export const mapStateToProps = createStructuredSelector({
  authenticatedUser: selectLoggedUser(),
  profile: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps))(App);
