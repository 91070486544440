import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import UserAccountStatus from 'shared/domain/user/profile/UserAccountStatus';
import { USER_ACCOUNT_STATUS_COLORS, USER_ACCOUNT_STATUS_TEXT } from 'app/components/UserStatus/UserStatus';

interface Props extends WithStyles<typeof styles> {
  userStatus: UserAccountStatus;
}

const UserStatusDot = ({ userStatus, classes }: Props) => (
  <div
    title={USER_ACCOUNT_STATUS_TEXT[userStatus]}
    className={classes.dot}
    style={{ backgroundColor: USER_ACCOUNT_STATUS_COLORS[userStatus] }}
  ></div>
);

const styles = () =>
  ({
    dot: {
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '10px'
    }
  }) as const;

export default withStyles(styles)(UserStatusDot);
